import React, {useState, useEffect} from "react";

function CarpentryBanner(props) {
    return(

        <section className="emergency_call_area carpentry-banner-area p-50">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="left flex-wrap andersen-banner-left-container">
                        <div>
                        <h2>Start Your Next Project</h2>
                            <h1>With Sunrise Carpentry.</h1>
                            <h4>Visit our site or find our showroom</h4>
                            <p>Experts in renovation, home remodeling & improvement</p>
                            <br/>
                            <a className="main_btn secondary_hero_btn" target="_blank" rel="noreferrer" href="https://sunrisecarpentry.com">Visit Site</a>
                            <a className="main_btn ml-1em main_btn_border" target="_blank" rel="noreferrer" href="https://www.google.com/search?q=sunrise%20carpentry&oq=sunrise+carpen&aqs=chrome.0.69i59j69i57j46i175i199j0l2j69i60l3.1959j0j4&sourceid=chrome&ie=UTF-8&tbs=lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2,lf:1,lf_ui:2&tbm=lcl&rflfq=1&num=10&rldimm=18151900701170787283&lqi=ChFzdW5yaXNlIGNhcnBlbnRyeUiW5cOW5oCAgAhaLgoRc3VucmlzZSBjYXJwZW50cnkQABABGAAYASIRc3VucmlzZSBjYXJwZW50cnmSAQljYXJwZW50ZXKqARkQASoVIhFzdW5yaXNlIGNhcnBlbnRyeSgA&ved=2ahUKEwjplrDV3pHvAhUQG80KHce-AbIQvS4wAHoECAcQIQ&rlst=f#rlfi=hd:;si:18151900701170787283,l,ChFzdW5yaXNlIGNhcnBlbnRyeUiW5cOW5oCAgAhaLgoRc3VucmlzZSBjYXJwZW50cnkQABABGAAYASIRc3VucmlzZSBjYXJwZW50cnmSAQljYXJwZW50ZXKqARkQASoVIhFzdW5yaXNlIGNhcnBlbnRyeSgA;mv:[[41.3147632,-73.7450819],[41.3143209,-73.81476649999999]];tbs:lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2,lf:1,lf_ui:2">Find Showroom</a>
                        </div>
                    </div>
                    <div className="right">
                        <div>
                            <img className="sunrise-carpentry-logo" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-carpentry.jpg' } alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

export default CarpentryBanner;