import React, {useState, useRef, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';
import { Form, Row, Col, InputGroup, Button, FormFile } from "react-bootstrap";
import axios from "axios";
import Recaptcha from "react-recaptcha";

const instance = axios.create({
	baseURL: '/api',
	timeout: 60000 // 60 seconds
});

function Mini_estimate(props) {
    const [data, setData] = useState(null);
	const [validated, setValidated] = useState(false);
	const [isHuman, setIsHuman] = useState(false);
	const [buttonText, setButtonText] = useState("Submit Form");
	const [emailSent, setEmailSent] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(null);

	useEffect(() => {
		return () => {
			setButtonText("Submit Form")
			setEmailSent(false);
			setValidated(false);
            setEmailSuccess(null);
			setIsHuman(false);
		}
	}, [props])

	const handleEmailSend = (first_name, last_name, phone, email, service, description) => {
		console.log("Sending email...");
		setEmailSent(true);

        try {
            instance.post('/estimate', {
                first_name: first_name,
                last_name: last_name,
                phone: phone,
                email: email,
                service: service,
                description: description
            }).then( res => {
                if (res.data.success) {
                    setEmailSuccess(true);
                } else setEmailSuccess(false);
            });
        } catch (err) {
			if (err) {
				setEmailSuccess(false);
			}
		}
	}

    function onloadCallback(e) {
        console.log("Captcha Loaded")
    }

	function verifyCallback(response) {
		if (response) {
			setIsHuman(true);
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		
			const form = event.currentTarget;
			console.log(form.elements.first_name.value)
			if (!form.checkValidity()) {
				event.preventDefault();
				event.stopPropagation();
				setValidated(false);
			} else {
				setValidated(true);
				handleEmailSend(
					form.elements.first_name.value,
					form.elements.last_name.value,
					form.elements.phone.value,
					form.elements.email.value,
					form.elements.service.value,
					form.elements.description.value
				);
				setButtonText("Sending...")
			}
		
	}
		
    return (
        <div className="free_consultation">
            <div className="title_top">
                <h5>The Sunrise Handyman</h5>
                <h3>Get a Free Estimate</h3>
            </div>
            <Form className="form-row contact_us_form" validated={validated} onSubmit={ (e) => handleSubmit(e) }>
                <Form.Group as={Col} md="6" sm="6" controlId="validationCustom01">
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                        name="first_name"
                        required
                        type="text"
                        placeholder="First name"
                        disabled={emailSuccess === true ? true : false}
                    />
                </Form.Group>
                <Form.Group as={Col} md="6" sm="6"  controlId="validationCustom02">
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                        name="last_name"
                        required
                        type="text"
                        placeholder="Last name"
                        disabled={emailSuccess === true ? true : false}
                    />
                </Form.Group>
                <Form.Group as={Col} md="12" controlId="validationService">
                    <Form.Label>Service Type</Form.Label>
                    {
                        props.service ?
                        <Form.Control name="service" required type="text" as="select" disabled>
                            <option value={ props.service }>{ props.service }</option>
                        </Form.Control>
                        :
                        <Form.Control name="service" required type="text" as="select" disabled={emailSuccess === true ? true : false}>
                            <option value="service and maintenance">Service & Maintenance</option>
                            <option value="mechanical repairs">Mechanical Repairs</option>
                            <option value="painting">Painting</option>
                            <option value="safety and mobility">Safety & Mobility</option>
                            <option value="installation">Installation</option>
                            <option value="skylights">Skylights</option>
                            <option value="front doors">Front Doors</option>
                            <option value="deck renovation">Deck Renovation</option>
                            <option value="flooring">Flooring</option>
                            <option value="windows and doors">Windows & Doors</option>
                            <option value="power washing">Power Washing</option>
                            <option value="roof shampoo">Roof Shampoo</option>
                            <option value="drywall">Drywall</option>
                            <option value="tile and masonry">Tile & Masonry</option>
                            <option value="exterior">Exterior</option>
                        </Form.Control>
                    }
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                        name="phone"
                        type="tel"
                        placeholder="Phone Number"
                        disabled={emailSuccess === true ? true : false}
                    />
                </Form.Group>
                <Form.Group className="mb-3" as={Col} md="6" controlId="validationEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                        name="email"
                        required
                        type="text"
                        placeholder="Email Address"
                        disabled={emailSuccess === true ? true : false}
                    />
                </Form.Group>
                <div className="form-group col-lg-12" style={{ zIndex:"100" }}>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" controlId="validationCustom03">
                            <Form.Label>Project Description</Form.Label>
                            <Form.Control
                                name="description"
                                required
                                as="textarea"
                                style={{ height: '100px' }}
                                disabled={emailSuccess === true ? true : false}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please enter a description of your project.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </div>
                <div style={{ display:"flex",justifyContent:"center",alignItems:"center",width:"100%" }} className="form-group col-lg-12" style={{ zIndex:"100" }}>
                {/*
                { !emailSuccess === true ?
                    <Recaptcha
                        sitekey="6LcPNhUcAAAAALAeGm0uSdFN1Zvbgn1THT3naeEJ"
                        onloadCallback={(e) => onloadCallback(e)}
                        verifyCallback={(e) => verifyCallback(e)}
                        className="mb-4"
                        style={{ display: emailSuccess === true ? 'none' : 'initial' }}
                    />
                    :
                    ''
                }
                */}
                    
                    {
				    !emailSuccess === true ?
                        <Fragment>
                            <Button aria-label="Submit Estimate Request" style={{ maxWidth:"unset" }} type="submit" className="btn submit_btn">{ emailSuccess === false ? "Submit Form" : buttonText }</Button>
                            {
                                emailSuccess === false ? 
                                <p className="text-danger" style={{ width:'100%',marginTop:'1em',textAlign:'left' }}>Woops! We couldn't send your request. Please try submitting the form again.</p>
                                :
                                ''
                            }
                        </Fragment>
                    :
                        <Fragment>
                            <p className="black">Estimate request sent!</p>
                            <p className="black">Keep an eye on your inbox. We will get back to you soon.</p>
                        </Fragment>
                    }



                </div>
            </Form>
        </div>
    )
}

export default Mini_estimate;