import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';

function VeluxBanner(props) {
    return(
        <section className="emergency_call_area andersen-banner-area velux-banner-area p-50">
            <div className="container andersen-banner-container">
                <div className="d-flex justify-content-around ">
                   
                    <div className="left">
                        <div>
                            <h1>Certified Installer of VELUX<span>&#174;</span> Skylights.</h1>
                            <p className="no-italic" style={{ marginBottom: "1em" }}>Backed by the VELUX<span>&#174;</span> No Leak Promise, we specialize in skylight installation.</p>
                  
                            <Link to="/estimate" className="main_btn white_btn">Get an Estimate</Link>
                            <Link to="/service/skylights" className="main_btn white_btn_alt ml-1em">Skylights</Link>
                      
                        </div>
                    </div>
                    <div className="right flex-wrap andersen-banner-left-container">
                        <div>
                            <img className="velux-banner-logo mt-md-4" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/vlux_certified.jpeg' } alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VeluxBanner;