
import React, {useState, useEffect} from "react";
import { Fragment } from "react";
import { Link } from 'react-router-dom';

function AndersenBanner(props) {

    function buttons() {
        if (props.buttons) {
            return (
                <Fragment>
                    <Link to="/services" className="main_btn white_btn">Get an Estimate</Link>
                    <Link to="/Andersen" className="main_btn white_btn_alt ml-1em">Windows & Doors</Link>
                </Fragment>
            )
        }
    }
    return(
        <section className="emergency_call_area andersen-banner-area p-50">
            <div className="container andersen-banner-container">
                <div className="d-flex justify-content-around ">
                    <div className="left flex-wrap andersen-banner-left-container">
                        <div>
                            <img className="andersen-banner-logo" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/Certified_Contractor_Square_Logo_4_Color_White_background_for_digital_.jpg' } alt=""/>
                        </div>
                    </div>
                    <div className="right">
                        <div>
                            <h1>Proud to be an</h1>
                            <h1>Andersen<span style={{ fontSize:"25px", verticalAlign:"top", lineHeight:"unset" }}>&trade;</span> Certified Contractor.</h1>
                            <h4>Backed by the #1 Trusted Window & Door Brand.*</h4>

                            <p>*2020 Andersen Brand surverys of U.S. Contractors, Builders & Architects.</p>
                            
                            { buttons() }
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AndersenBanner;