import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ReviewsCarousel from "../../components/ReviewsCarousel";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function PowerWashing(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Power-washing" pageTitle="Power-washing" pageLink="/power-washing" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Power-washing</h4>
                                    <p>Power-washing is a technique that uses high pressure water to remove dirt and mildew from exterior surfaces. However, with such high pressure, it is important to know which surfaces to pressure-wash. Inexperienced DIY-ers may be tempted to pressure-wash their own homes but risk damaging their surfaces and property such as paint chipping which could lead to rotting wood.</p>
                                    <p>Pressure-washing can help freshen up your siding, walkways, driveway, foundation, and deck. We recommmend pressure-washing if you notice the build-up of dirt, algar, grime, mildew, and fungus on your exterior.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-2">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/power_washing.jpg' } alt=""/></div>     
                            </div>

                            <div className="col-lg-12 mt-4">
                                <div className="maintence_text">
                                    <h4 className="black">Roof Shampoo - <span style={{ color: '#d63343' }}>Coming Soon</span></h4>
                                    <h5 style={{ color: '#d63343' }}><i>Got mold and mildew, ask for Sunrise Shampoo!</i></h5>
                                    <p>Our new roof shampooing service is an safe roof cleaning solution for asphalt and fiberglass shingle roofs. Roof Shampooing does not affect the structural integrity of your roof or harm surrounding landscaping. We use a non-chloric solution that is applied using a special sprinkler-disc system that will only remove the mold, mildew, and stains from your roof.</p>
                                    <p>Give us a call and ask about our new Roof Shampoo service. A happy roof is a clean roof.</p>
                                </div>
                            </div>
                        </div>

						<div className="row maintence_column">
                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Power-washing or Sunrise Shampoo</small>
                                    <div className="icon">
                                    <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

      </Fragment>
    )
}

export default PowerWashing;
