import React, {useState, useRef, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';
import { Form, Row, Col, InputGroup, Button, FormFile } from "react-bootstrap";
import axios from "axios";
import Recaptcha from "react-recaptcha";

const instance = axios.create({
	baseURL: '/api',
	timeout: 60000 // 60 seconds
});

function Estimate(props) {
	const [validated, setValidated] = useState(false);
	const [isHuman, setIsHuman] = useState(false);
	const [buttonText, setButtonText] = useState("Submit Form");
	const [emailSent, setEmailSent] = useState(false);
	const [emailSuccess, setEmailSuccess] = useState(null);

	useEffect(() => {
		return () => {
			setButtonText("Submit Form")
			setEmailSent(false);
			setValidated(false);
			setEmailSuccess(null);
			setIsHuman(false);
		}
	}, [props])

	const handleEmailSend = (first_name, last_name, phone, email, service, description) => {
		console.log("Sending email...");
		setEmailSent(true);

		try {
			instance.post('/estimate', {
				first_name: first_name,
				last_name: last_name,
				phone: phone,
				email: email,
				service: service,
				description: description
			}).then( res => {
				if (res.data.success) {
					setEmailSuccess(true);
				} else setEmailSuccess(false);
			});
		} catch (err) {
			if (err) {
				setEmailSuccess(false);
			}
		}

	}

	function onloadCallback(e) {
        console.log("Captcha Loaded")
    }

	function verifyCallback(response) {
		if (response) {
			setIsHuman(true);
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		
		const form = event.currentTarget;
		console.log(form.elements.first_name.value)
		if (!form.checkValidity()) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(false);
		} else {
			setValidated(true);
			handleEmailSend(
				form.elements.first_name.value,
				form.elements.last_name.value,
				form.elements.phone.value,
				form.elements.email.value,
				form.elements.service.value,
				form.elements.description.value
			);
			setButtonText("Sending...")
		}
		

	}
		
    return(
      <Fragment>

        <section className="breadcrumb_area estimate-breadcrumbs">
        	<div className="container">
        		<div className="breadcrumb_inner">
        			<h2 className="text-align-center">Get a Free Estimate</h2>
					<p className="text-align-center white">Submit the form below and we'll get back to you as soon as we can!</p>
        		</div>
        	</div>
        </section>

        <section className="contact_details_area p100">
			{
				!emailSuccess === true ?
				<div className="container center">
					<div className="row">

						<div className="col-lg-12">
							<div className="estimate-form-wrapper" style={{ paddingBottom: "150px", background: "#fff", paddingLeft: "2em", paddingRight: "2em" }}>

								<Form validated={validated} onSubmit={ (e) => handleSubmit(e) }>
									<Row className="mb-3">
										<Form.Group as={Col} md="6" sm="6" controlId="validationCustom01">
											<Form.Label>First name</Form.Label>
											<Form.Control
												name="first_name"
												required
												type="text"
												placeholder="First name"
											/>
										</Form.Group>
										<Form.Group as={Col} md="6" sm="6"  controlId="validationCustom02">
											<Form.Label>Last name</Form.Label>
											<Form.Control
												name="last_name"
												required
												type="text"
												placeholder="Last name"
											/>
										</Form.Group>
									</Row>
									<Row className="mb-3">
										<Form.Group as={Col} md="6" controlId="validationPhone">
											<Form.Label>Phone Number</Form.Label>
											<Form.Control
												name="phone"
												type="tel"
												placeholder="Phone Number"
											/>
										</Form.Group>
										<Form.Group className="mb-3" as={Col} md="6" controlId="validationEmail">
											<Form.Label>Email Address</Form.Label>
											<Form.Control
												name="email"
												required
												type="text"
												placeholder="Email Address"
											/>
										</Form.Group>
									</Row>
									
									<Row className="mb-3">
										<Form.Group as={Col} md="6" controlId="validationService">
											<Form.Label>Service Type</Form.Label>
											<Form.Control name="service" required type="text" as="select">
												<option value="service and maintenance">Service & Maintenance</option>
												<option value="mechanical repairs">Mechanical Repairs</option>
												<option value="painting">Painting</option>
												<option value="safety and mobility">Safety & Mobility</option>
												<option value="installation">Installation</option>
												<option value="skylights">Skylights</option>
												<option value="front doors">Front Doors</option>
												<option value="deck renovation">Deck Renovation</option>
												<option value="flooring">Flooring</option>
												<option value="windows and doors">Windows & Doors</option>
												<option value="power washing">Power Washing</option>
												<option value="roof shampoo">Roof Shampoo</option>
												<option value="drywall">Drywall</option>
												<option value="tile and masonry">Tile & Masonry</option>
												<option value="exterior">Exterior</option>
											</Form.Control>
										</Form.Group>
									</Row>
									
									<Row className="mb-3">
										<Form.Group as={Col} md="12" controlId="validationCustom03">
											<Form.Label>Project Description</Form.Label>
											<Form.Control
												name="description"
												required
												as="textarea"
												style={{ height: '125px' }}
											/>
											<Form.Control.Feedback type="invalid">
												Please enter a description of your project.
											</Form.Control.Feedback>
										</Form.Group>
									</Row>

									{/*
									<Recaptcha
										sitekey="6LcPNhUcAAAAALAeGm0uSdFN1Zvbgn1THT3naeEJ"
										onloadCallback={(e) => onloadCallback(e)}
										verifyCallback={(e) => verifyCallback(e)}
										className="mb-4"
									/>
									*/}

									<Button aria-label="Submit Estimate Request" type="submit" disabled={ emailSuccess === true ? true : false } className="btn submit_btn btn-small">Submit Request</Button>
								</Form>
								{
									emailSuccess === false ? 
									<p className="text-danger" style={{ width:'100%',marginTop:'1em',textAlign:'left' }}>Woops! We couldn't send your request. Please try submitting the form again.</p>
									:
									''
								}
							</div>
						</div>
						
					</div>
				</div>
				:
				<div className="response-sent p-100">
					<div className="container">
						<h4>Estimate Request Sent!</h4>
						<p>Keep an eye on your inbox - we will get back to you soon!</p>
						{ window.scrollTo(0, 0) }
					</div>
				</div>
			}	
        </section>


      </Fragment>
    )
}

export default Estimate;