import React, {useState, useEffect, Fragment, createRef} from "react";
import { Link } from 'react-router-dom';

import Hero from "../components/Hero";
import Clients from "../components/Clients"
import AndersenBanner from "../components/AndersenBanner";
import Breadcrumbs from "../components/Breadcrumbs";

import Mini_estimate from "./Mini_estimate";

import {Helmet} from "react-helmet";

function Andersen(props) {
    const [data, setData] = useState(null);

    return(
      <Fragment>

		<Helmet>
          <title>The Sunrise Handyman | Andersen Windows & Doors</title>
          <meta name="description" content="The Sunrise Handyman | Andersen - Licensed and certified Andersen contractor, hand-picked to install and repair Andersen Windows and Doors." />
        </Helmet>

		<Breadcrumbs pageName="Windows & Doors" pageTitle="Windows & Doors" pageLink="/Andersen" />

		<section className="andersen-page-banner">
			<img style={{ width: "100%" }} src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/windows-narrow-lrg.png' } alt=""/>
		</section>

        <section className="about_text_area p_100">
        		<div className="ab_text_inner">
        			<div className="ab_text_item">
						<section>
								<div className="container">
									<div className="main_title">
										<h3><b className="primary-red">Windows, Doors, and More...</b></h3>
										<p>Choosing the right contractor is important for any project, but especially window and door installation. As a licensed and certified Andersen contractor, we can ensure the optimal performance and life expectancy of your Andersen windows and doors.</p>
										
										<br/>

										<div className="row ab_list">
											<div className="col-lg-5 d-flex justify-content-center">
												<div className="ab_logo text-center">
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/aboutus-andersen.png' } alt=""/>
												</div>
											</div>
											<div className="col-lg-7">
												<h3><b className="black">Andersen Licensed & Certified</b></h3>
												<p>Andersen Certified Contractors are true craftsmen who are hand-picked by Andersen's team of industry experts and receive product training and installation resources. Our expertise and experience in an extensive range of residential projects allows us to capture the vision for your home and to work with you to provide the right products for your project.</p>
											</div>
										</div>
									</div>
								</div>

								 <section className="our_team_area p_50">
									<div className="container">
										<div className="main_title">
											<h2>The Andersen Certified Contractor Advantage</h2>
										</div>
										<div className="row our_team_inner">
											<div className="col-lg-3 col-sm-6">
												<div className="team_item">
													<div className="team_img">
														<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/expert-craftsmen.jpg' } alt=""/>
													</div>
													<div className="team_text">
														<h4>Expert Craftsmen</h4>
														<p>Our experienced and expertly-trained team share your passion for quality, performance and reliability.</p>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-sm-6">
												<div className="team_item">
													<div className="team_img">
														<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/continued-success.jpg' } alt=""/>
														
													</div>
													<div className="team_text">
														<h4>Confidence</h4>
														<p>Vetted, trained and backed by the industry leader, Certified Contractors have extensive window and door replacement knowledge to make any project a success.</p>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-sm-6">
												<div className="team_item">
													<div className="team_img">
														<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/exclusive-access.jpg' } alt=""/>
													
													</div>
													<div className="team_text">
														<h4>Exclusive Access</h4>
														<p>With a our Andersen certification, you’ll have access to the Andersen 2-year limited installation warranty and financing options for your project. </p>
													</div>
												</div>
											</div>
											<div className="col-lg-3 col-sm-6">
												<div className="team_item">
													<div className="team_img">
														<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/premium-products.jpg' } alt=""/>
														
													</div>
													<div className="team_text">
														<h4>Premium Products</h4>
														<p>Access to the full Andersen portfolio of products, including the best-selling 400 Series windows and patio doors. From modern to contemporary styles, we provide stylish, reliable and durable options to bring your project to life.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</section>
								
						</section>
	    			</div>
        		</div>

				<section className="choose_contract_area andersen_choose_contract_area p_50">
					<div className="container">
							<div className="contract_part">
								<div className="row">
									<div className="col-lg-8">
									<div className="ab_text_item">
										<h3><b className="primary-red">Andersen Services & Maintenance</b></h3>
										<h4 className="black">Bring your vision to life with our trusted window and door experts...</h4>
										<br/>
										<p className="contract_text">We offer a wide range of Andersen services from basic repairs, sash replacement, to full installation and conversion kits. Our team are trained to cover all aspects of advanced installation and abide by all applicable building codes and regulations.</p>
									</div>
										<div className="contract_text">
											<p>Start your home improvement project right with the expertise of an Andersen Certified Contractor. We're backed by the #1 most trusted and recommended brand of window and doors* and are dedicated to bringing your dream home to life.</p>
											<ul className="nav hp-services-list">
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Factory Trained Technicians</span>           
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Andersen Parts</span>           
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Window Sash Replacement</span>          
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Conversion Kits</span>           
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Window & Door Installation</span>          
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Weather-stripping</span>           
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Andersen Hardware & Handles</span>           
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Service & Maintenance</span>           
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Full Window & Door Replacement</span>           
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Andersen Service Calls</span>            
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Identifying Parts & Repairs</span>            
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Storm Door Installation</span>            
												</li>
												<li>
													<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Window & Door Realignment</span>           
												</li>
											</ul>
										</div>
									</div>
									<div className="col-lg-4">
										<Mini_estimate service="Andersen Windows & Doors"/>
									</div>
								</div>
							</div>
						</div>
        			</section>


        	
        </section>

		<AndersenBanner buttons={ false }/>

        <div className="pb-100"></div>

        <Clients type="clients" />

      </Fragment>
    )
}

export default Andersen;