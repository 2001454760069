import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

import { Helmet } from "react-helmet";

function Drywall(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Helmet>    
          <title>The Sunrise Handyman | Drywall</title>
          <meta name="description" content="The Sunrise Handyman | Drywall - Repair, installation, molding, trim, wainscoating, painting - One Call Does it All..." />
        </Helmet>

        <Breadcrumbs pageName="Drywall" pageTitle="Drywall Repair and Installation" pageLink="/drywall" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Drywall Repair and Installation</h4>
                                    <p>No matter how long you have lived in your home, you're going to need to patch and repair interior walls. Life happens and our walls see eveything from dooknob holes, nail holes, scratches from pets, dents from kids or water damage. As well as drywall (also known as Sheetrock) repair, we offer a suite of upgrade services such as crown molding and trim, built-in bookshelves and cabinetry, shelving, mantles, fireplaces, wainscoating, and accessory walls and pillars.</p>
                                </div>
                            </div>
                            <div className="col-lg-5">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/drywall.jpg' } alt=""/></div> 
						    </div>
                        </div>

						<div className="row maintence_column">
							<div className="col-lg-7">
                                <h4 className="primary-red">Crown Molding and Trim</h4>
                                <p>Elevate your home with the finishing touches. Intricate crown molding and trim help raise eyebrows and give your home an elegant feel. We offer the ultiamte attention to detail when it comes to detailed trim, partnering with our parent company, Sunrise Carpentry. Give us a call or step into our showroom to see our crown molding and trim options.</p>
							</div>
                            <div className="col-lg-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/drywall.jpeg' } alt=""/></div> 
							</div>

                            <div className="col-lg-7">
                                <h4 className="primary-red">Wainscoating Repair and Installation</h4>
                                <p>Add definition and personality to your walls with wainscoating. These raised panels add an extra layer to your wall and come in different styles and heights - most go back to the colonial period and give your home a traditional aesthetic but others can give a modern and elegant feel.</p>
							</div>
                            <div className="col-lg-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/wainscoating.jpeg' } alt=""/></div> 
							</div>

                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Drywall - Repair and Installation</small>
                                    <div className="icon">
                                    <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  

							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

		

      </Fragment>
    )
}

export default Drywall;
