import React, {useState, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';

import Clients from "../components/Clients";


function Error_404() {
 
    return(
      <Fragment>

        <section className="breadcrumb_area">
        	<div className="container">
        		<div className="breadcrumb_inner">
              <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/van-transparent-small.png' } alt=""/>
        			<h2>Oops!</h2>
              <ul className="nav">
                <li><h4>Sorry, that page doesn't exist.</h4></li>
              </ul>
              <ul className="nav">
                <li><h4><Link to="/">Home</Link></h4></li>
              </ul>
        		</div>
        	</div>
        </section>


        <section className="repair_system_area p-100">
        	<div className="container">
        		  <div className="center_title_big text-center">
                <h2>Let's put you in the right direction...</h2>
              </div>
             
              <div className="row repair_system_inner">
                <div className="col-lg-3 col-sm-6">
                  <Link to="/">
                  <div className="rp_box_item">
                    <h4>Home</h4>
                    <div className="icon">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </div>
                  </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/services">
                    <div className="rp_box_item">
                      <h4>Services</h4>
                      <div className="icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/about">
                    <div className="rp_box_item">
                      <h4>About Us</h4>
                      <div className="icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/contact">
                    <div className="rp_box_item">
                      <h4>Contact Us</h4>
                      <div className="icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <Link to="/tips">
                    <div className="rp_box_item">
                      <h4>Tips & Ideas</h4>
                      <div className="icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/testimonials">
                    <div className="rp_box_item">
                      <h4>Testimonials</h4>
                      <div className="icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/estimate">
                    <div className="rp_box_item">
                      <h4>Get an Estimate</h4>
                      <div className="icon">
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
        </section>

        <section className="our_moto_area">
        	<div className="left_side">
        		<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/our-motto.png' } alt=""/>
        	</div>
        	<div className="right_side">
        		<div className="moto_text">
              <h1>Our Motto :</h1>
					    <h2>The Home of the One Call Does it All...</h2>
        			<p>With an experienced team of home improvement speicalists, we stand behind our motto and gaurantee a timely dispatch and service to your home with one call.</p>
                    <div className="mt-50">
                    <a className="main_btn secondary_hero_btn" href="https://sunrisecarpentry.com" target="_blank">Get an Estimate</a>
                    </div>
        		</div>
        	</div>
        </section>


        <Clients type="clients" />

      </Fragment>
    )
}

export default Error_404;