import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ReviewsCarousel from "../../components/ReviewsCarousel";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

import { Helmet } from "react-helmet";

function Carpentry(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Helmet>
          <title>The Sunrise Handyman | Carpentry</title>
          <meta name="description" content="The Sunrise Handyman | Carpentry - As a division of Sunrise Carpentry, we have over 25 years experience in residential and commercial carpentry.." />
        </Helmet>

        <Breadcrumbs pageName="Carpentry" pageTitle="Carpentry" pageLink="/carpentry" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Carpentry</h4>
                                    <p>Carpentry is in our blood. After all, Sunrise Handyman services were born from our parent company <strong>Sunrise Carpentry</strong>. With over 25 years of residential and commercial carpentry work in the Greater Westchester Area, we have a portfolio of custom decks, patios, pergulas, kitchen cabinetry, and home remodeling projects.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-2">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-carpentry.jpg' } alt=""/></div> 
						    </div>
                        </div>

						<div className="row maintence_column">
							<div className="col-lg-7">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">Sunrise Carpentry</span></h4>
                                    <div className="mb-3"><i>Quality Work at the Crack of Dawn</i></div>
                                    <p>We provide a wide range of residential and commmerical home improvement and remodeling services in Westchester, Putnam County, New York, and Fairfield County, Connecticut.</p>
                                    <p>From the early stages of planning, design, finishing, trim, and follow-up maintenance, we offer a full white-glove service for all of carpentry and home remodeling projects. Looking for more than a photo album for inspiration? Come and see our design center and workshop in Yorktown Heights for a consultation where you can meet our expert craftsment and carpenters with samples of custom decking, pergulas, kitch cabinetry, windows, and doors. </p>
                                    <p>We handle projects from start to finish. Our team is well seasoned and available to you throughout your project. Your satisfaction is our priority. If you have any questions please contact us directly!</p>
                                    <a href="https://www.thermatru.com/explore-products/Difference/" rel="noreferrer" target="_blank" className="btn submit_btn mb-4">Learn More About Sunrise Carpentry</a>
								</div>
							</div>

                            <div className="col-lg-5">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/office-2.jpeg' } alt=""/></div>
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/office-1.jpeg' } alt=""/></div> 
							</div>


                            <div className="col-lg-7 mt-4">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/carpentry-team.jpeg' } alt=""/></div>
							</div>

                            <div className="col-lg-5 mt-4">
								<div className="maintence_text">
                                    <h4>What we do...</h4>
								    <ul className="nav flex-column">
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Custom Decks Pool Decks</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Custom Built-ins, Molding, Trim</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Kitchen Cabinetry</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Patios and Pergulas</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Fencing and Gates</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Bathroom Vanities</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Archways and Doorways</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Fireplaces ad Mantels</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Kitchen Islands</li>
                                    </ul>
                                </div>
							</div>

                            <div className="col-lg-12 mt-4">
                            
                                    <h4 className="black">Find Our Showroom</h4>
                                    <p>3 Old Tomahawk St, Yorktown Heights, NY 10598</p>

                                    <section className="map_area">
                                        <div style={{ width: "100%" }}>
                                            <iframe title="map" style={{width:"100%",height:"200px"}} frameborder="0" scrolling="no" src="https://maps.google.com/maps?width=100%25&amp;height=800&amp;hl=en&amp;q=Sunrise%20Carpentry%20Old%20Tomahawk%20Street,Yorktown%20Heights,%20NY%2010598+(The%20Sunrise%20Handyman)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                                        </div>
                                    </section>
                               
                            </div>

                            <div className="container">
                                <div className="single_border_title mt-5 mb-4 pb-3">
                                    <h4>Recent Projects</h4>
                                </div>
                                <div className="gallery_inner row imageGallery1 ">
                                    <div className="col-lg-4 col-sm-6 geo air">
                                        <div className="gallery_item">
                                            <img className="img-fluid"src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/wood-deck.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 heat indoor">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/outdoors.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                
                                    <div className="col-lg-4 col-sm-6 air indoor">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/jetty.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 heat indoor">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/pool-house.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 air indoor">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/island-2.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 geo air">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/glass-deck.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 geo indoor">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/portico.jpg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 heat maintain">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/deck-stilts.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 air indoor">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/kitchen-decor-1.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 air indoor">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/island.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 geo maintain">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/appliances.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 geo maintain">
                                        <div className="gallery_item">
                                                <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/carpentry.jpeg' } alt=""/>
                                                <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
      
                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Carpentry - Custom Decks, Patios, Exterior, Cabinetry</small>
                                    <div className="icon">
                                     <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

      </Fragment>
    )
}

export default Carpentry;
