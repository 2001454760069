import React, {Fragment, useState, useEffect} from "react";
import { withRouter, Redirect, Link, useHistory } from 'react-router-dom';

import './css/App.css';
import './css/Responsive.css';
import './css/font-awesome.min.css';
import './vendors/owl-carousel/assets/owl.carousel.min.css';
import './vendors/time-date-picker/tempusdominus-bootstrap-4.min.css';
import './vendors/lightbox/simpleLightbox.css';
import './vendors/popup/magnific-popup.css';
import './vendors/revolution/css/settings.css';
import './vendors/revolution/css/layers.css';
import './vendors/revolution/css/navigation.css';

import Routes from "./Routes";

import AppendScript from "./components/AppendScript";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App(props) {
  const history = useHistory();

	function onloadCallback() {
		console.log("captcha loaded");
	}
	window.onloadCallback = onloadCallback;

  useEffect(() => {
    AppendScript("./js/theme.js");
    window.scrollTo(0, 0);
    document.querySelector(".navbar-toggler").classList.add("collapsed");
    document.querySelector(".navbar-collapse").classList.add("collapse");
    document.querySelector(".navbar-collapse").classList.remove("show");
    document.querySelector(".navbar-toggler").setAttribute('aria-expanded', false);
  }, [props.location.pathname]);

  return (
    <Fragment>
      <Header/>
      <Routes/>
      <Footer/>
    </Fragment>
  );

}

export default withRouter(App);
