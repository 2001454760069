import React, {useState, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ReCAPTCHA from "react-google-recaptcha";

function SellingYourHome(props) {
    const [data, setData] = useState(null);
    const recaptchaRef = React.createRef();
 
    return(
      <Fragment>

       <Breadcrumbs pageName="The Ultimate Guide to Selling Your Home" pageTitle="The Ultimate Guide to Selling Your Home" pageLink="/ultimate-guide-to-selling-your-home" background_size="small" hide_van={ true } />

        <section className="testimonials_area pad_top pb_0">
            <div className="container">
              <div className="main_title article-title-flex">
                <div className="flex-left">
                  <h1 className="article-title">The Ultimate Guide to Selling Your Home</h1>
                  <p>Preparing to put yours or your client's home on the market? With our 25+ years of experience maintaining and repairing homes in the Greater Westchester County Area, we've put together the ultimate guide and checklist to get your home ready for market.</p>
                  <br/>
                  <p>From touching up paint to replacing your water heater, we believe this checklist will help you get the best offer for your home. Don't give any reason to recall your home as "the one with the pink bathroom". We encourage you to use our guide to allow your house to shine and get the maximum potential for your investment.</p>
                </div>
                <div className="flex-right">
                  <img className="blog-thumbnail" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/hp-selling-guide-thumbnail.png' } alt=""/>
                </div>
              </div>
            </div>
      
        </section>

        <section className="checklist-section choose_contract_area">
        	<div className="container">
        		<div className="contract_part">
        			<div className="row">
        				<div className="col-lg-12">
        					<div className="contract_text">
        						<h2 className="article-subtitle">Step 1: <span style={{ color: "#000" }}>The Checklist - 30 Days Before</span></h2>
								    <p>Once you have an ideal date in mind for listing your home, one month before, compare this checklist with the current state of your home and check off what you have already completed. This will give you a good idea of how much time you need to set aside to each task.</p>
                    <div className="container">
                    <div className="row">
                        <div className="mt-3 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Exterior</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Paint siding, trim, doors and shutters.</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Power-wash decking and walkways</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair cracks in driveway</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Unblock leave from gutters</span>           
                            </li>
                            <li>
                              <div className="red-square"></div><span className="ml-15px">Improve curb appeal with shrubs and flowers</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add chimney spark arrestor</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Remove trees and bushes that are too close to the foundation</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Extend water run-off away from foundation</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair old wood decking</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace inefficient windows and doors</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add safety hand rails to porch steps</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add bright driveway lighting</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace old and broken fence panels</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Remove overhanging and aging trees</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair or replace old roof and shingles</span>           
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Kitchen</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace worktops with marble or granite</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Ensure outlets are GFCI compliant</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace cabintet door handles</span>           
                            </li>
                            <li>
                             <div className="red-square"></div> <span className="ml-15px">Repair leaking faucet and sink</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace out-dated cabinetry</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add outlets with USB ports</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace light switches and outlets</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace old appliances with smart/modern ones</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add new pendant lights above kitchen island</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace out-dated backsplash</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repaint ceiling, walls, and trims</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair leaking or faulty skylight</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace out-dated floor tiles</span>           
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Bathrooms</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Ensure outlets are GFCI compliant</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add modern or floating vanity</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace mirrors and lighting</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Ensure toilet and sink are affixed properly</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace old caulking around sinks and bathtubs</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace light switches and outlets</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace shower doors and exclosures</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair leaking sinks and faucets</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add his and hers sinks to master bathroom</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace out-dated floor tiles</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repaint walls with light or neutral colors</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace shower exhaust vent and lighting</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace shower curtain and rails</span>           
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Basement</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Clean up any water residue from past leaks</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair and patch foundation cracks</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair and replace corroded copper pipes</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Service or repair boiler</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Check for and remediate any mold</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Fix yard drainage to extend away from the foundation</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Fix yard drainage to extend away from the foundation</span>           
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Bedrooms</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Sand and restain hardwood floors</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade door handles and trim</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair and clean window screens</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repaint walls, doors, and trim</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace light switches and outlets</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Update closet poles and shelving</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair or upgrade window blinds</span>           
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Living Areas</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade and modernize light fixtures</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade wall trim or crown molding</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repaint walls, doors, and trim</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Patch floor scratches or sand and restain hardwood</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace floor t-molding and seam binders</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace light switches and outlets</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade and modernize interior doors</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace or upgrade fireplace mantel</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Replace or upgrade fireplace mantel</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair leaking skylights</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade windows and doors</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repair and patch drywall scratches and cracks</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add smart-home accessories (thermostat, light switches..)</span>           
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Attic</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Improve circulation or add soffit vents</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Remove moisture issues or remediate mold</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Service or repair air conditioning</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add or improve insulation</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Patch any holes or roof leaks</span>           
                            </li>
                          </ul>
                        </div>

                        <div className="mt-4 bt-lightgray pt-40px w-100 pb-5">
                          <h3 style={{ color: "#ff9400" }}>Garage</h3>
                          <ul className="nav hp-services-list">
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repaint garage floor and walls</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade or install organized shelving</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade to a fire-reated garage door</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add accessible fire extinguisher</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade lighting and fixtures</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Declutter and organize storage areas</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Upgrade or repair garage door mechanisms and sensors</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Repaint and clean garage doors</span>           
                            </li>
                            <li>
                              <div className="red-square"></div> <span className="ml-15px">Add overhead or integrated heating</span>           
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
        					</div>
        				</div>
        			</div>
        		</div>
        	</div>
        </section>


        <section className="checklist-section choose_contract_area" style={{ marginBottom: "2em" }}>
        	<div className="container">
        		<div className="contract_part">
        			<div className="row">
        				  <div className="col-lg-7">
                    <div className="contract_text">
                      <h2 className="article-subtitle">Step 2: <span style={{ color: "#000" }}>Get to work!</span></h2>
                      <p>Once you have identified which repairs and upgrades you can do yourself, begin to check them off of your list when completed. For those tasks that require expertise or more man-power, give us a call and schedule services to be completed before your listing date.</p>
                      <p>Remember, home buyers are going to be much more critical of your house then they are of their own. Be sure to stay on top of your checklist as well as cleaning and dusting weekly. As you naviagte through these tasks, think about what is going to add the biggest 'wow-factor' to your home. Try to make atleast one upgrade or addition to each room such as crown molding, new flooring, windows, light fixtures - giving buyers a reason to remember not only your home, but each room.</p>
                      <div className="container">
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <img className="step2-image" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/doll-house.jpg' }/>
                  </div>
        			</div>
        		</div>
        	</div>
        </section>


        <section className="checklist-section choose_contract_area" style={{ marginBottom: "5em" }}>
        	<div className="container">
        		<div className="contract_part">
        			<div className="row">
        				  <div className="col-lg-8">
                    <div className="contract_text">
                      <h2 className="article-subtitle">Step 3: <span style={{ color: "#000" }}>Upgrades</span></h2>
                      <p>While navigating your list, you will have identitied a number of upgrades you need to make. This is an area in which you can significantly increase the value of your home by choosing products and services from household names.</p>

                      <p>Think about how you can leverage brand names in your marketplace listing that are known for excellence and used relaiably throughout the United States. For example, if you are going to upgrade your windows and doors, choose Andersen or Thermatru. Here is a list of products and services backed for the quality and value:</p>                      
                      <div className="client_service_area" style={{ paddingTop: "0px", paddingBottom: "10px" }}>
                        <div className="container">
                            <div className="client_slider owl-carousel">
                                <div className="item">
                                    <a href="https://www.veluxusa.com/" target="_blank">
                                        <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/velux-300x300.png' } style={{ maxWidth: "125px"}}/>
                                    </a>
                                </div>
                                <div className="item">
                                    <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/hp-hero-slider/thermatru-300x300.png' }/>
                                </div>
                                <div className="item">
                                    <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/timbertech-300x300.png' } style={{ maxWidth: "160px"}}/>
                                </div>
                                <div className="item">
                                    <a href="https://www.trex.com/find-a-contractor/details/?PRO=12473TP#/0" target="_blank">
                                        <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/trexpro-300x300.png' }/>
                                    </a>
                                </div>
                                <div className="item">
                                    <a href="https://www.andersenwindows.com/where-to-buy/42/427293/#about" target="_blank">
                                        <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/hp-hero-slider/andersen-300x300.png' }/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*

                      <div className="upgrades-container mb-5 mt-5">

                        <div className="emergency_call_area andersen-banner-area p-20">
                            <div>
                                <img style={{ width: "40%" }} src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/andersen-horizontal.png' } alt=""/>
                            </div>

                            <div className="mt-3">
                                <h4>Andersen<span style={{ fontSize:"25px", verticalAlign:"top", lineHeight:"unset" }}>&trade;</span> Windows & Doors</h4>
                            </div>
                        </div>

                        <div className="row mt-5">
                          <div className="col-lg-7">
                            <h4>Andersen Windows & Doors</h4>
                            <p>We recommend choosing Andersen for window & door replacement as we stand behind their un-matched craft and design as the best window and door products in the industry.</p>
                            <p>This claim is reinforced by recent surveys of U.S. homeowners, contractors, builders, architects, and realtors which yielded the following results:</p>
                            <br/>
                            <p>
                              <ul>
                                <li>Andersen® is the #1 Trusted & Recommended Window & Door Brand</li>
                                <li>Andersen® Products Rate #1 in Quality & Performance</li>
                                <li>Realtors & Homeowners Agree that Andersen® Products Increase the Value of a Home by at least 10%</li>
                                <li>Andersen Carries the Best Selection of Contemporary Windows & Doors</li>

                                <p>https://www.youtube.com/watch?v=fHTifAcLyQg</p>
                                <p>https://www.youtube.com/watch?v=Zoko9yBoXp8</p>
                              </ul>
                            </p>
                          </div>
                          <div className="col-lg-5 flex align-items-center justify-content-center">
                            <img style={{ width: "90%" }} src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/andersen-horizontal.png' }/>
                          </div>
                        </div>
                      </div>

                      <div className="mb-5">
                        <h5>VELUX Roof Windows, Skylights & Blinds</h5>
                      </div>

                      <div className="mb-5">
                        <h5>TREX Composite Decks and Railing</h5>
                      </div>

                      <div className="mb-5">
                        <h5>Therma-Tru Entry, Exterior, and Patio Doors</h5>
                      </div>

                      <div className="mb-5">
                        <h5>TimberTech Composite Decks and Outdoor Living Products</h5>
                      </div>

                    */}

                    </div>
                  </div>

                  <div className="col-lg-4">
        					  <div className="free_consultation">
        						  <div className="title_top">
                        <h5>The Sunrise Handyman</h5>
                        <h3>Get a Free Estimate</h3>
                      </div>
                      <form className="form-row contact_us_form" action="contact_process.php" id="contactForm">
                        <div className="form-group col-lg-6">
                          <input type="text" className="form-control" name="name" id="name" placeholder="First Name"/>
                        </div>
                        <div className="form-group col-lg-6">
                          <input type="text" className="form-control" name="lname" id="lastname" placeholder="Last Name"/>
                        </div>
                        <div className="form-group col-lg-6">
                          <input type="text" className="form-control" name="number" id="phone" placeholder="Phone"/>
                        </div>
                        <div className="form-group col-lg-6">
                          <input type="email" className="form-control" name="email" id="email" placeholder="Email"/>
                        </div>
                        <div className="form-group col-lg-12">
                          <textarea className="form-control" name="message" id="message" rows="1" placeholder="Message"></textarea>
                        </div>
                        <div className="form-group col-lg-12" style={{ zIndex:"100" }}>
                            <button aria-label="Submit Estimate Request" type="submit" value="submit" className="btn submit_btn">submit now</button>
                            <ReCAPTCHA
                              sitekey="6Le1XokaAAAAAOy13fIpSxcqMmCvbxDGaZCX-eQm"
                              ref={recaptchaRef}
                              size="invisible"
                              badge="bottomleft"
                              //onChange={onChange}
                            />
                        </div>
                      </form>
							      </div>
        				  </div>
        			</div>
        		</div>
        	</div>
        </section>



        <section className="checklist-section choose_contract_area" style={{ marginBottom: "5em" }}>
        	<div className="container">

          
              

              </div>
              </section>

      </Fragment>
    )
}

export default SellingYourHome;