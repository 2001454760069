import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function Mechanical(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Mechanical Repairs" pageTitle="Mechanical Repairs" pageLink="/mechanical-repairs" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
						<div className="s_details_main">
							{ /*<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/s-details-2.jpg' } alt=""/>*/ }
							<h4 className="capitalize">Mechanical Repairs</h4>
							<p>Even if you are more of a weekend-warrior or handy with some aspects of home DIY, mechanical service and repairs can be daunting and costly to most home owners. Wether you are looking to replace your boiler, improve energy efficiency or fix a leaky faucet, we can help point you in the right direction.</p>
							<p>We've been around for a while so we have a network of expert plumbers, electricians, and HVAC servicers that we can refer you to.</p>
						</div>
						<div className="row maintence_column">
							<div className="col-lg-4">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/counter.jpeg' } alt=""/></div> 
							</div>
							<div className="col-lg-8">
								<div className="maintence_text">
                                    <h4>What we offer...</h4>
									<p>Don't need the full services of an electrican, plumber or HVAC contractor? We do provide a list of light mechanical repairs:</p>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Light fixtures</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Light switches</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Drywall repair</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Faucet repair and installation</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Shower vents</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Dryer vent repair</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Bathroom remodeling</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Kitchen renovation</li>
									</ul>
								</div>
							</div>

							<div className="col-lg-12 col-sm-12 mt-4">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Mechanical Repairs - Light Electrical, Plumbing & HVAC</small>
                                    <div className="icon">
									<Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  

							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

		

      </Fragment>
    )
}

export default Mechanical;
