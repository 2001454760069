import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

import { Helmet } from "react-helmet";

function Skylights(props) {
 
    return(
      <Fragment>

        <Helmet>
          <title>The Sunrise Handyman | Skylights</title>
          <meta name="description" content="The Sunrise Handyman | Skylights - Certified VELUX Skylight installer." />
        </Helmet>

        <Breadcrumbs pageName="Skylights" pageTitle="Skylights" pageLink="/skylights" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Skylights</h4>
                                    <p>Skylights are an impressive addition to any room. They give your home more natural light and fresh air, and are a great center piece or accessory. Skylight innovation has come a long way in recent years and can be added to any room, regardless of room size, ceiling angle or height. There are also a number of additional features available on the market today including rain sensors, integrated bathroom fans, dimming, tilting, and lighting.</p>
                                </div>
                            </div>
                            <div className="col-lg-5">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/vlux_certified.jpeg' } alt=""/></div> 
						    </div>
                        </div>
						<div className="row maintence_column">
							<div className="col-lg-7">
								<div className="maintence_text">
									<h4>Certified <span className="primary-red">VELUX</span> Skylight Installer</h4>
                                    <p>We are proud to be backed by the world leading skylight seller and named as a Certified VELUX Installer. We are confident in VELUX products and stand behind their promise as the "No Leak Skylight" supplier. As a VELUX installer, we are here to guide you through the full installation process. Just looking to add more light into your home? We'll give you a skylight consultation and walk you through the VELUX catalog of skylights:</p>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Sun Tunnel Skylights </li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Modular Skylights</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Skylight Blinds</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Solar Powered Skylights</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Electric Skylights</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Fixed Skylights</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> Flat Roof Skylights</li>
									</ul>
								</div>
							</div>
                            <div className="col-lg-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/skylight-sky.jpg' } alt=""/></div> 
							</div>

                            <div className="col-lg-12 mt-5">
								<div className="maintence_text">
									<h4>Can you install a skylight on a flat roof?</h4>
                                    <p>Yes! You can install a skylight on almost any roof or ceiling type. It is a common misconception that skylights can only be added to vaulted ceilings. However, having a flat ceiling can actually be used as an advantage. The process of installing includes creating a light shaft between the flat ceiling and the attic or roof above. The space between the attic and the ceiling create a nice focal point to the room which will help the space feel larger. Watch the VELUX video animation that demonstrates the process: </p>
								</div>
							</div>
                            <div className="col-lg-12 mt-3">
								<div className="maintence_img">
                                    <iframe width="100%" height="350" src="https://www.youtube.com/embed/mWWs_-tjrW8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div> 
							</div>

                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Skylights - Repair and Installation</small>
                                    <div className="icon">
                                        <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>
      </Fragment>
    )
}

export default Skylights;
