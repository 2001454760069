import React, {useState, useEffect, Fragment, createRef} from "react";
import { Link } from 'react-router-dom';

import LazyLoad from 'react-lazyload';

import Hero from "../components/Hero";
import Clients from "../components/Clients"
import ReviewsCarousel from "../components/ReviewsCarousel";
import AndersenBanner from "../components/AndersenBanner";
import VeluxBanner from "../components/VeluxBanner";
import CarpentryBanner from "../components/CarpentryBanner";
import Mini_estimate from "./Mini_estimate";

import {Helmet} from "react-helmet";

function Home(props) {
    const [data, setData] = useState(null);
    const [isHuman, setIsHuman] = useState(false);

    function onloadCallback() {
      console.log("captcha loaded")
    }
  
    function verifyCallback(response) {
      console.log(response)
      if (response) {
        setIsHuman(true);
      }
    }
 
    return(
      <Fragment>
        <Helmet>
          <title>The Sunrise Handyman | Home</title>
          <meta name="description" content="The Sunrise Handyman | The Home of the One Call Does it All..." />
        </Helmet>

        <div className="homePage">
        <Hero/>
        <LazyLoad>
          <Clients type="social" />
        </LazyLoad>
        <section className="repair_system_area mb-100px">
        	<div className="container">
        		  <div className="center_title_big text-center">
                <h2>What can we do for you?</h2>
                <p>With over 25 years of service, there's a good chance we have experience in the services you are looking for. We offer a range of both residential and commercial services from general assembly, custom decks, window and door installation, to major renovation and repairs. Don't see what you're looking for? Give us a call!</p>
              </div>
             
              <div className="row repair_system_inner">
                <div className="col-lg-3 col-sm-6">
                  <Link to="/service/carpentry">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img className="img-sponsor-thumbnail sponsor-thermatru" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-carpentry.jpg' } alt="Sunrise Carpentry"/>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/carpentry.jpeg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Carpentry</h4>
                        <p>We have a full team of expert craftsmen along with our parent company, Sunrise Carpentry, to build and install decks, cabinets, shelving, and more.</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/Andersen">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img className="img-sponsor-thumbnail" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/Certified_Contractor_Square_Logo_4_Color_White_background_for_digital_.jpg' } alt="Andersen Windows & Doors - Certified Contractor"/>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/windows_doors.jpeg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Windows & Doors</h4>
                        <p>We are a certified Andersen contractor with a team of expert installers of all Andersen windows and doors. We offer a list of window and door services.</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/service/drywall">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/drywall.jpg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Drywall</h4>
                        <p>Patching up holes, wear n' tear or changing the layout of your home? Remove, build, and transform your walls with help from our licensed and insured team.</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/service/safety-and-mobility">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/mobility.jpg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Safety & Mobility</h4>
                        <p>We can make the neccessary upgrades to meet the requirements of building code standards or help aging home-oweners feel more comfortable in their homes.</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/service/installation">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/installation.jpeg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Installation</h4>
                        <p>Whatever it is, we've probably installed it before. Appliances, flooring, windows, doors, kitchen cabinetry.. we do it all!</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link to="/service/skylights">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img className="img-sponsor-thumbnail sponsor-velux" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/vlux_certified.jpeg' } alt="Certified VELUX Skylight Installer"/>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/skylights.jpg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Skylights</h4>
                        <p>Skylights are a speciality of ours. As a certified VELUX<span style={{ fontSize: "0.5em" }}>&#174;</span> installer, we stand behind thier quality and no-leak promise.</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <Link Link to="/service/decks">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img className="img-sponsor-thumbnail sponsor-trex" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/trexpro-platinum.jpeg' } alt="TREX Pro Installer - TREX Decking"/>
                          <img className="img-sponsor-thumbnail sponsor-timbertech" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/timbertech.png' } alt="TimberTech Decking"/>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/sunrise-decking.jpg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Deck Renovation</h4>
                        <p>Maintenance, repairs, power-washing, painting, and more. We're certified TREX Pros (Platinum) with a portfolio of custom decks.</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>
                
                <div className="col-lg-3 col-sm-6">
                  <Link to="/service/front-doors">
                    <div className="m_service_item">
                      <div className="service_img">
                        <LazyLoad>
                          <img className="img-sponsor-thumbnail sponsor-thermatru" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/therma-tru.png' } alt="Therma-Tru Certified Installer"/>
                          <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/frontdoors.jpeg' }  alt=""/>
                        </LazyLoad>
                      </div>
                      <div className="service_text">
                        <h4>Front Doors</h4>
                        <p>Make a bold first impression with a solid front door. As a certified ThermaTru Front Door Installer, you're in good hands.</p>
                        <span>More info</span>
                      </div>
                    </div>
                  </Link>
                </div>            
              </div>

              <div className="center-all">
                <Link to="/services" className="main_btn main_btn_alt-bg">View All Services</Link>
              </div>
            </div>
        </section>

        <AndersenBanner buttons={ true }/>

        <section className="choose_contract_area pad_top">
        	<div className="container">
        		<div className="contract_part">
        			<div className="row">
        				<div className="col-lg-8">
        					<div className="contract_text">
        						<h4>One Call Does it All...</h4>
								    <p>Established in 1994, we're a team of experienced, and knowledgeable craftsmen, and designers who are on the cutting edge in the remodeling industry. The Sunrise Handyman is a business designed to service customers just like you. Whether it's a loose board or a simple picture frame, The Sunrise Handyman is here to complete that task list that just won't go away. So just give us a call because one call does it all...</p>
                    <ul className="nav hp-services-list">
                      <LazyLoad>
                        <li>
                          <Link to="/service/skylights" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt="" /> <span className="ml-35px">VELUX Skylight Installation</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/carpentry" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Carpentry</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/tile" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Masonry, Tilework</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/decks" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Deck Renovation</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/safety-and-mobility" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Handicap ramps & modifications</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/maintenance" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Seasonal maintenance</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/maintenance" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Roof Shampoo (mold & mildew)</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/maintenance" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Repairs (leaks, creaks, cracks)</span></Link>             
                        </li>
                        <li>
                          <Link to="/service/flooring" href="#"><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> <span className="ml-35px">Flooring install & repair (hardwood, vinyl, tile)</span></Link>             
                        </li>
                      </LazyLoad>
                    </ul>
        					</div>
        				</div>
        				<div className="col-lg-4">
        					<Mini_estimate />
        				</div>
        			</div>
        		</div>
        	</div>
        </section>

        <VeluxBanner />


        <ReviewsCarousel/>

        <div className="mt-3em"></div>

        <CarpentryBanner />

        <section className="experience_area p_100">

            <div className="container">
              <div className="row experience_inner">
                <div className="col-lg-8">
                  <div className="experience_text">
                    <h3>The Ultimate Guide <br />to Selling Your Home</h3>
                    <h5>A to-do list for real estate agents and home-owners - how to get more money for your home.</h5>
                    <p>Preparing to put yours or your client's home on the market? With our 25+ years of experience maintaining and repairing homes in the Greater Westchester County Area, we've put together the ultimate guide and checklist to get your home ready for market. From touching up paint to replacing your water heater, we believe this checklist will help you get the best offer for your home.</p>
                    <Link to="/ultimate-guide-to-selling-your-home"><span className="main_btn main_btn_alt-bg" target="_blank" href="">Read More</span></Link>
                  </div>
                </div>
                <div className="col-lg-4">
                  <LazyLoad>
                    <img className="hp-thumbnail" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/hp-selling-guide-thumbnail.png' } alt=""/>
                  </LazyLoad>
                </div>
              </div>
            </div>
        </section>

        <Clients type="clients" />

        </div>
      </Fragment>
    )
}

export default Home;