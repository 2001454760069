

import React, {useState, useEffect} from "react";
import { Fragment } from "react";
import { Link } from 'react-router-dom';

function ServiceFooter(props) {

    return(

        <div class="s_details_inner">			
            <div class="row mt-5 award_box">
                <div class="col-lg-4 p-0">
                    <div class="award_box_item">
                        <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/award-4.png' } alt=""/>
                        <h4>Accredited Business</h4>
                        <p>Don't just take it from us... take a look at the brands that back us! We're certified contractors of trusted household names such as Andersen Windows & Doors, VELUX Skylights, and TREX Decks.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="award_box_item">
                        <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/award-5.png' } alt=""/>
                        <h4>Industry Experts</h4>
                        <p>Over 25 years of experience in carpentry and home improvement services. We're confident we can serve you and protect your home with our one call does it all brand claim...</p>
                    </div>
                </div>
                <div class="col-lg-4 p-0">
                    <div class="award_box_item">
                        <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/award-6.png' } alt=""/>
                        <h4>Licenced & Insured</h4>
                        <p>Looking for reassurance? We're licensed and insured in Westchester and Putnam Counties, and Connecticut. You're in good hands with our expert craftsmen.</p>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default ServiceFooter;
