import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ReviewsCarousel from "../../components/ReviewsCarousel";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

import { Helmet } from "react-helmet";

function Exterior(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

		<Helmet>    
          <title>The Sunrise Handyman | Exterior</title>
          <meta name="description" content="The Sunrise Handyman | Exterior - We stand behind our brand promise that One Call Does it All. Ask us about our exterior services, including landscaping, windows, doors, drainage, masonry, decks, siding, and custom decks." />
        </Helmet>

        <Breadcrumbs pageName="Exterior" pageTitle="Exterior" pageLink="/exterior" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Exterior</h4>
                                    <p>Your home's exterior is what is seen first by guests, potential buyers, and the street. Minor upgrades and regular exterior maintenance will give your home that year-round wow-factor as well reducing costly repairs in the future.</p>

                                    <p>We have a number of impressive exterior projects under our belt, including pool decks, pergulas, walkways, porches, exterior structures, and full back-yard entertainment renovation.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-2">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/exterior-2.jpeg' } alt=""/></div> 
						    </div>
                        </div>


						<div className="row maintence_column">

                            <div className="col-lg-7">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">Pool Houses & External Structures</span></h4>
                                    <p>A new fixed external structure such as a shed or pool-house can change your property by adding more space for storage or give guests a spot for entertainment or simple relaxing spot to avoid the summer heat. Not sure where to start? We can help design your perfect outdoor space.</p>
								</div>
							</div>

                            <div className="col-lg-5">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/pool-house.jpeg' } alt=""/></div>
							</div>

                            <div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4><span className="primary-red">Exterior Service & Maintenance</span></h4>
									<p>Outdoor maintenance can be exhausting, especially in our North-Eastern seasonal climate. However, regular seasonal maintenance can dramatically improve the look and value of your home.</p>
									<p>We're available Spring, Summer, Autumn, and Winter to provide a list of services from boosting your curb appeal to building custom decks and patios.</p>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Custom Decks (Certified TREX Deck and TimberTech installers)</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Siding repair and replacement</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Drains and gutters</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Power-washing</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Windows and doors</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Pool house design and installation</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Landscaping</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Fences and gates</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-5 mt-5">
                                <div className="maintence_img mt-2"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/pergula.jpeg' } alt=""/></div> 
								<div className="maintence_img mt-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/maintenance.jpeg' } alt=""/></div> 
							</div>
                        
                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Exterior Services</small>
                                    <div className="icon">
									<Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

      </Fragment>
    )
}

export default Exterior;
