import React, {useState, useEffect} from "react";
import { Link, useHistory } from 'react-router-dom';

function Header(props) {

    const history = useHistory();

    function andersenPageRedirect() {
        let path = `/Andersen`; 
        history.push(path);
    }    

    return(
        <header className="header_area menu_three">
        <div className="contact_info">
            <div className="container">
                <div className="info_inner d-flex justify-content-end">
                    <a href="tel:9142487368" className="mobile-hide">
                        914
                        <img className="bolt" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/bolt.png' } alt="" />
                        248
                        <img className="bolt" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/bolt.png' } alt="" />
                        7368
                    </a>
                    <a href="mailto:info@thesunrisehandyman.com" className="mr-0 mobile-hide">
                        info@thesunrisehandyman.com
                    </a>
                    <Link to="/estimate" className="flex-center-all">
                        <div className="primary-cta main_btn flex-center-all" role="button">
                            Get an Estimate
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <div className="wood-grain-bg"></div>
        <div className="main_menu">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <Link to="/home" className="navbar-brand">
                        <img style={{ marginLeft: "20px" }} src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-handyman-logo-trans.png' } alt=""/>
                        { /*<img style={{ marginLeft: "20px" }} src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-favicon.png' }/>*/ }
                        <div className="parent-company-banner"><i>A Division of <span style={{ color: "#cf3535", fontWeight: "bold" }}>Sunrise Carpentry</span></i></div>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span></span> 
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="nav navbar-nav ml-auto">
                            <li className="dropdown">
                                <Link to="/home">Home</Link>
                            </li>
                            <li className="dropdown submenu">
                                <Link to="/services" className="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">Services </Link>
                                <i className="fa fa-angle-down mobile_dropdown" aria-hidden="true" data-toggle="dropdown"></i>
                                <ul className="dropdown-menu">
                                    <li><Link to="/service/maintenance" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true">Service & Maintenance</Link></li>
                                    <li><Link to="/service/mechanical-repairs" className="dropdown-toggle" data-toggle="dropdown">Mechanical Repairs</Link></li>
                                    <li><Link to="/service/Painting" className="dropdown-toggle" data-toggle="dropdown">Painting</Link></li>
                                    <li><Link to="/service/safety-and-mobility" className="dropdown-toggle" data-toggle="dropdown">Safety & Mobility</Link></li>
                                    <li><Link to="/service/installation" className="dropdown-toggle" data-toggle="dropdown">Installation</Link></li>
                                    <li><Link to="/service/skylights" className="dropdown-toggle" data-toggle="dropdown">Skylights</Link></li>
                                    <li><Link to="/service/front-doors" className="dropdown-toggle" data-toggle="dropdown">Front Doors</Link></li>
                                    <li><Link to="/service/carpentry" className="dropdown-toggle" data-toggle="dropdown">Carpentry</Link></li>
                                    <li><Link to="/service/decks" className="dropdown-toggle" data-toggle="dropdown">Deck Renovation</Link></li>
                                    <li><Link to="/service/flooring" className="dropdown-toggle" data-toggle="dropdown">Flooring</Link></li>
                                    <li><Link to="/Andersen" className="dropdown-toggle" data-toggle="dropdown">Windows & Doors</Link></li>
                                    <li><Link to="/service/power-washing" className="dropdown-toggle" data-toggle="dropdown">Power Washing</Link></li>
                                    <li><Link to="/service/drywall" className="dropdown-toggle" data-toggle="dropdown">Drywall Repair</Link></li>
                                    <li><Link to="/service/tile" className="dropdown-toggle" data-toggle="dropdown">Tile</Link></li>
                                    <li><Link to="/service/exterior" className="dropdown-toggle" data-toggle="dropdown">Exterior</Link></li>
                                </ul>
                            </li>
                            <li><Link to="/tips" className="dropdown-toggle" data-toggle="dropdown">Tips & Ideas</Link></li>
                            <li><Link to="/about" className="dropdown-toggle" data-toggle="dropdown">About</Link></li>
                            <li><Link to="/contact" className="dropdown-toggle" data-toggle="dropdown">Contact</Link></li>
                            <li><Link to="/testimonials" className="dropdown-toggle" data-toggle="dropdown">Testimonials</Link></li>

                        </ul>

                        <ul className="nav navbar-nav navbar-right">
							<li className="search" onClick={() => andersenPageRedirect()}>
                                <Link to="/Andersen" className="dropdown-toggle z-index-priority" data-toggle="dropdown">
                                    <img className="andersen-menu-item" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/andersen-horizontal.png' } alt="" /><span>Service & Maintenance</span>
                                </Link>
                            </li>
						</ul>

                    </div>
                </nav>
            </div>
            <div className="banner-stripe"></div>
        </div>

    </header>
    )
}

export default Header;
