import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ReviewsCarousel from "../../components/ReviewsCarousel";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function Flooring(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Flooring" pageTitle="Flooring" pageLink="/flooring" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Flooring</h4>
                                    <p>Sunrise Handyman is your total solution for flooring installation and repair. We offer servicing for all floor types including hardwood, vinyl, laminate, linoleum, carpet and, ceramic and porcelain title. We stand behind our <i>one call does it all</i> mantra and provide full flooring renovation from removal, installation, cleanup, and maintenance.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-2">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/flooring.jpg' } alt=""/></div> 
						    </div>
                        </div>


						<div className="row maintence_column">

                            <div className="col-lg-7">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">Ceramic and Porcelain Tile</span></h4>
                                    <p>Looking for tile and grout repair or full installation, we have a strong portfolio of tile projects including full kitchen and bathroom flooring installation. New tile can give your home a refreshed look, espcially in high-traffic areas such as your foyer, hallways, and kitchen. If you are pepraring your to sell your home, it is often suggested that refreshing your bathrooms can help increase sale value and attract more potential buyers.</p>
								</div>
							</div>

                            <div className="col-lg-5">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/tile-2.jpeg' } alt=""/></div>
							</div>

                            <div className="col-lg-7">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">Wood Flooring</span></h4>
                                    <p>Looking for new installation or wood board repair from pets or general wera-n-tear, give us a call. From repair, installation, sanding, and staining, we do it all. Give us a call and we will dispatch one of our flooring experts to consult with you on wood flooring options.</p>
								</div>
							</div>

                            <div className="col-lg-5">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/hardwood.jpeg' } alt=""/></div>
							</div>

                            <div className="horizontal-rule mt-5"></div>

                            <div className="container">
                                <h4 className="mb-0"><span className="primary-red">Kitchens & Bathrooms</span></h4>
                                <p>Take a look at some of our recent kitchen and bathroom tile projects.</p>
                                <div className="gallery_inner row imageGallery1 ">
                                    <div className="col-lg-4 col-sm-6 geo air">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/tile.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 heat indoor">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/tile-2.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                
                                    <div className="col-lg-4 col-sm-6 air indoor">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/shower-1.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 geo indoor">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/shower-2.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 heat maintain">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/shower-5.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 air indoor">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/shower-6.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 heat indoor">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/kitchen-2.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 air indoor">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/kitchen-3.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 geo air">
                                        <div className="gallery_item">
                                            <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/tile-3.jpeg' } alt=""/>
                                            <i className="icon icon-Search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Flooring Installation & Repair</small>
                                    <div className="icon">
                                    <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

      </Fragment>
    )
}

export default Flooring;
