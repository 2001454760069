import React, {useState, useEffect, Fragment} from "react";
import { Form, Row, Col, InputGroup, Button, FormFile } from "react-bootstrap";

import axios from "axios";

import {Helmet} from "react-helmet";

const instance = axios.create({
	baseURL: '/api',
	timeout: 60000 // 60 seconds
});


function Contact(props) {
	const [data, setData] = useState(null);
	const [validated, setValidated] = useState(false);
	const [buttonText, setButtonText] = useState("Submit Form");
	const [emailSent, setEmailSent] = useState(false);

	useEffect(() => {
		return () => {
			setButtonText("Submit Form")
			setEmailSent(false);
			setValidated(false);
		}
	}, [props])
	

	const handleEmailSend = (first_name, last_name, email, description) => {
		console.log(email)
		console.log("Sending email...");

		instance.post('/contact', {
			first_name: first_name,
			last_name: last_name,
			email: email,
			description: description
		}).then( res => {
			setEmailSent(true);
		});
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;
		if (!form.checkValidity()) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(false);
		} else {
			setValidated(true);
			handleEmailSend(
				form.elements.first_name.value,
				form.elements.last_name.value,
				form.elements.email.value,
				form.elements.description.value
			);
			setButtonText("Sending...")
		}
		
	}
		
    return(
      <Fragment>

		<Helmet>
          <title>The Sunrise Handyman | Contact</title>
          <meta name="description" content="The Sunrise Handyman | Contact." />
        </Helmet>

        <section className="breadcrumb_area">
        	<div className="container">
        		<div className="breadcrumb_inner">
					<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/van-transparent-small.png' } alt=""/>
        			<h2>Contact Us</h2>
					<ul className="nav">
						<li><a href="index.html">Home </a></li>
						<li>Contact Us</li>
					</ul>
        		</div>
        	</div>
        </section>

		{!emailSent ?
        <section className="contact_details_area p_100">
        	<div className="container">
        		<div className="row">
        			<div className="col-lg-5">
        				<div className="contact_details_text">
        					<div className="media">
        						<div className="d-flex">
        							<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/map-marker.png' } alt=""/>
        						</div>
        						<div className="media-body">
        							<h4>Address :</h4>
        							<p>The Sunrise Handyman<br/>
										3 Old Tomahawk Street,<br/>
										Yorktown Heights, NY 10598
									</p>
        						</div>
        					</div>
        					<div className="media">
								<div className="d-flex">
        							<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/phone-2.png' } alt=""/>
        						</div>
        						<div className="media-body">
        							<h4>Phone :</h4>
        							<a href="tel:9142487368">(914)-248-7368</a>
        						</div>
        					</div>
        					<div className="media">
        						<div className="d-flex">
        							<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/icon/inbox.png' } alt=""/>
        						</div>
        						<div className="media-body">
        							<h4>Email :</h4>
        							<a href="mailto:info@thesunrisehandyman.com">info@thesunrisehandyman.com</a>
        						</div>
        					</div>
        				</div>
        			</div>
        			<div className="col-lg-7">
        				<div className="contact_form_area">
						<section className="map_area">
          <div style={{ width: "100%" }}>
            <iframe title="map" style={{width:"100%",height:"600px"}} frameborder="0" scrolling="no" src="https://maps.google.com/maps?width=100%25&amp;height=800&amp;hl=en&amp;q=Sunrise%20Handyman%20Old%20Tomahawk%20Street,Yorktown%20Heights,%20NY%2010598+(The%20Sunrise%20Handyman)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
          </div>
        </section>
        				</div>
        			</div>
        		</div>
        	</div>
        </section>
		:
		<div className="response-sent p-100">
			<div className="container">
				<h4>Message Sent!</h4>
				<p>Keep an eye on your inbox - we will get back to you soon!</p>
				{ window.scrollTo(0, 0) }
			</div>
		</div>
		}

      </Fragment>
    )
}

export default Contact;