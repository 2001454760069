import React, {useState, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

function Footer() {
    const [year, setYear] = useState(new Date().getFullYear());

    return(
    <Fragment>
        <section className="replacement_area cta-footer-section p-1-5em">
            <div className="cta-wood-bg"></div>
        	<div className="container">
        		<div className="media mobile-flex-center-all">
        			<div className="d-flex">
						<LazyLoad>
                        	<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-handyman-icon.png' } alt=""/>
						</LazyLoad>
        			</div>
        			<div className="media-body">
        				<h4>Free Estimates on all services, maintenance, and repairs</h4>
        				<h5>Call Now 
							<a className="footer-phone-number" href="tel:9142487368">
								914
							
									<img className="bolt" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/bolt.png' } alt="" />
							
								248
				
									<img className="bolt" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/bolt.png' } alt=""/>
				
								7368
							</a>
						</h5>
        			</div>
        		</div>
        	</div>
        </section>

        <footer className="footer_area">
        	<div className="footer_widgets">
        		<div className="container">
        			<div className="row">
        				<div className="col-lg-4 col-sm-6">
        					<aside className="f_widgets contact_widget">
        						<div className="f_title">
        							<h3>Contact Us</h3>
        						</div>
        						<address className="footer_address">
        							<p>The Sunrise Handyman<br /> 3 Old Tomahawk Street,<br /> Yorktown Heights, NY 10598</p>
        							<p>Phone : <a href="tel:9142487368">(914)-248-7368</a></p>
        						</address>
        					</aside>
        				</div>
        				<div className="col-lg-4 col-sm-6">
        					<aside className="f_widgets link_widget">
        						<div className="f_title">
        							<h3>Menu</h3>
        						</div>
        						<ul className="nav">
        							<li><Link to="/">Home</Link></li>
									<li><Link to="/contact">Contact Us</Link></li>
									<li><Link to="/About">About Us</Link></li>
									<li><Link to="/testimonials">Testimonials</Link></li>
									<li><Link to="/tips">Tips & Ideas</Link></li>
									<li><Link to="/services">Services</Link></li>
						
        						</ul>
        					</aside>
        				</div>
        				<div className="col-lg-4 col-sm-6">
        					<aside className="f_widgets payment_widget">
        						<div className="media flex-col" >
        							<div className="media-body">
        								<h4>A Division of Sunrise Carpentry</h4>
        							</div>
									<a target="_blank" rel="noreferrer" href="https://sunrisecarpentry.com">
										<div className="flex-col">
											<LazyLoad>
												<img className="mt-mb-1em" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/over-25-years.png' } style={{ maxHeight:"125px", height: '125px' }} alt="" />
												<img className="mt-mb-1em" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/lead-safe-certified-firm.png' } style={{ maxHeight:"90px", height: '90px' }} alt="" />
											</LazyLoad>
											<LazyLoad>
												<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-carpentry.jpg' } style={{ maxWidth:"200px" }} alt="" />
											</LazyLoad>
										</div>
									</a>
        						</div>
        					</aside>
        				</div>
        			</div>
        		</div>
                <div className="footer-licenses">
                    <p>Westchester Lic. WC07318-H96</p>
                    <p>Putnam Lic. PC2240-A</p>
                    <p>Connecticut Lic. HIC.0622485</p>
                </div>
        	</div>
        	<div className="footer_copyright">
        		<div className="container">
        			<div className="d-flex justify-content-center">
        				<div className="text-align-center">
        					<p>Copyright © Sunrise Handyman, { year }. All right reserved.</p>
        				</div>
						{
						/*
        				<div className="right">
        					<a href="#">Privacy Policy</a>
        					<a href="#">Sitemap</a>
        				</div>
						*/
						}
        			</div>
        		</div>
        	</div>
        </footer>
    </Fragment>
    )
}

export default Footer;