import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function Painting(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Painting" pageTitle="Painting" pageLink="/painting" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
						<div className="s_details_main">
							{ /*<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/s-details-2.jpg' } alt=""/>*/ }
							<h4 className="capitalize">Painting</h4>
							<p>Sometimes it's the small things that make a big difference in your home. Frshen up your walls with a fresh coat of paint or bring life back to your wood deck with a full sanding and stain. </p>
							<p>Some may say painting is easy, but good painting is difficult and time consuming. That is why we are here and have a team of professionals to offer paint touch-ups to full sanding, sealing, and paiting jobs.</p>
						</div>
						<div className="row maintence_column">
							<div className="col-lg-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/painting.jpg' } alt=""/></div> 
							</div>
							<div className="col-lg-7">
								<div className="maintence_text">
									<h4>Sanding, Sealing, Staining, Painting, and more...</h4>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Interior painting</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Exterior porch and deck painting</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Furniture painting and finishing</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Crown molding and trim painting and installation</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Wall accents and trim painting</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Floor sanding and staining</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Door painting</li>

									</ul>
								</div>
							</div>
							<div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4>Crown Molding, Trim, & Door painting</h4>
									<p>Adding that finished look to any room with detailed molding, trim, and door painting can be a challenge even for experienced DIY home-owners. Over the years, we have equipped the equipment and knowledge needed for trim and molding painting. It takes patience and careful attention to detail.</p>
								</div>
							</div>
                            <div className="col-lg-5 mt-5">
								<div className="maintence_img">
                                    <img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/molding.jpeg' } alt=""/>
                                </div> 
							</div>
                            <div className="col-lg-7 mt-5">

                                <div className="maintence_text">
									<h4>Deck Sanding & Staining</h4>
									<p>As well as professionally installing custom decks and porches, we know how to service and maintain them. As an extension of your home, your deck needs regular maintenance too.</p>
                                    <p>Decks experience a lot of seasonal wear during the heat of summer and bitter cold of winter. We can help restore rotting wood and peeling paint to make your deck look new again!</p>
								</div>
							</div>
							<div className="col-lg-5 mt-5">
								<div className="maintence_img mt-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/wood-deck.jpeg' } alt=""/></div> 
							</div>

							<div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Painting, Sanding, and Staining</small>
                                    <div className="icon">
									<Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  

							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

		

      </Fragment>
    )
}

export default Painting;
