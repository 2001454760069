import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ReviewsCarousel from "../../components/ReviewsCarousel";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

import { Helmet } from "react-helmet";

function Decks(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Helmet>
          <title>The Sunrise Handyman | Decks</title>
          <meta name="description" content="The Sunrise Handyman | Decks - Certified and licensed TREX Pro and TimberTech Deck installer." />
        </Helmet>

        <Breadcrumbs pageName="Deck Renovation & Repair" pageTitle="Deck Renovation & Repair" pageLink="/decks" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Deck Renovation & Repair</h4>
                                    <p>Your deck is part of your home. An outisde extension that takes a beating from the extremeties of our North-Eastern climate. Sunrise Handyman has a team of professional carpenters, certified TimberTech Installers, and TREX Deck Pros that can build, replace or reapir your deck so you are ready to entertain guests all year round. We work with all types of decks an deck accessories including composite, wood, light posts, pergulas, glass railing, pool decks and more. </p>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-2">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/sunrise-decking.jpg' } alt=""/></div> 
						    </div>
                        </div>



						<div className="row maintence_column">

                        <div className="col-lg-12">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">Deck Sanding, Staining, and Painting</span></h4>
                                    <p>For wood decks, it is recommended to sand and restain your deck every few years. To keep the wood from absorbing mositure and rotting, re-sealing and staining is the best way to protect your deck.</p>
								</div>
							</div>

                            <div className="col-lg-12">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">Power-washing</span></h4>
                                    <p>If your deck is already in good condition or recently painted, we recommend power-washing your deck after fall and winter to restore color and remove dirt built up from fall and winter. Looking for more pressure-washing services? Have us take care of other areas of your home, patio or driveway. Power-washing is a great way to restore and revive your home and surroundings.</p>
								</div>
							</div>

                            <div className="col-lg-12">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">Deck Maintenance and Repair</span></h4>
                                    <p>Over the years, we have installed countless composite and wood decks, patios, and pool decking. We know occasional repairs are required to ensure the life of your deck. From replacing wood boards, sanding, staining, or structural repair, one call does it all...</p>
								</div>
							</div>

                            <div className="horizontal-rule mt-3"></div>

							<div className="col-lg-8">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span>Certified TREX Pro</span></h4>
                                    <p>Trex Decks are one of the nations favorite deck choices for their hassle-free composite structure. Proven to last longer than typical wood decks, they do not require andy sanding, staining or painting.</p>
								</div>
							</div>

                            <div className="col-lg-4">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/trexpro-platinum.jpeg' } alt=""/></div>
							</div>

                            <div className="col-lg-12">
								<div className="maintence_text">
                                    <p>Your deck can sometimes feel like a second job with the constant maintenance and care. Trex Decks allow you focus more on your entertaining space while Trex takes care of itself. As a certified TREX Deck Pro, we are backed by TREX to install and repair their products. A Trex Pro is a professional committed to building quality decks using the Trex family of products. We adhere to the installation requirements and program guidelines so we can provide you with peace of mind.</p>
								</div>
							</div>

                            <div className="col-lg-12">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span className="primary-red">TREX Composite Decking vs. Wood</span></h4>
                                    <p>TREX composite decking has been proven to last longer and need less maintenance than wood decks. Watch the video below, provided by TREX, for a more in-depth comparison.</p>
								</div>
							</div>
                            <div className="col-lg-12 mt-2">
								<div className="maintence_img">
                                    <iframe width="100%" height="400" src="https://www.youtube.com/embed/FUHKmfnhRVA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div> 
							</div>

                            <div className="col-lg-8 mt-5">
								<div className="maintence_text">
                                    <h4 className="mb-0"><span>Certified TimberTech Installer</span></h4>
                                    <p>Timbertech offers some of the industry's leading technology and warranties. We believe a deck should stand the test of time and stand behind TimberTech's cutting edge technology. You get the full aesthetic experience of wood without the inefficiency and cost of wood. </p>
								</div>
							</div>

                            <div className="col-lg-4 mt-5">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/timbertech.png' } alt=""/></div>
							</div>

                            <div className="col-lg-12">
								<div className="maintence_text">
                                    <p>There is a wide ranch of wood tones and grains available. What's also better than real wood? Easy installation. Since TimberTech boards are significantly lighter than wood, installating and fastening (color-matched) is much quicker. As a certified TimeberTech partner, Sunrise offeres professional installation and special warranties you cannot get from retailers.</p>
								</div>
							</div>
                            

                        
                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Deck Renovation & Repair</small>
                                    <div className="icon">
                                    <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

      </Fragment>
    )
}

export default Decks;
