import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function SafetyMobility(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Safety & Mobility" pageTitle="Safety & Mobility" pageLink="/safety-mobility" />

        <section class="service_details_area p_100">
        	<div class="container">
        		<div class="row flex-row-reverse">
				
        			<div class="col-lg-9">
						<div class="s_details_main">
							{ /*<img class="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/s-details-2.jpg' } alt=""/>*/ }
							<h4 className="capitalize">Safety & Mobility</h4>
							<p>Looking to adapt yours or your loved one's home for a safer, more comfortable place? We're here to help!</p>
							<p>We're home remodeling professionals with experience in adapting homes for the elderly and less-abled. Installing hand rails, ramps or full home mobility modification... <i>one call does it all.</i></p>
                            <p>Looking for regular service and maintenance for yours or your loved ones home? Give us a call to discuss regular maintenance packages.</p>
						</div>
						<div class="row maintence_column">
							<div class="col-lg-7">
								<div class="maintence_text">
									<h4>What we offer...</h4>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Hand rails, guard rails, and ramps</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Doors, door handles, and doorway modification</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Bathroom remodeling</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Light fixtures and light switches</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Moving assistance and installation</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Furniture modification and assembly</li>
									</ul>
								</div>
							</div>
                            <div class="col-lg-5">
								<div class="maintence_img"><img class="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/mobility.jpg' } alt=""/></div> 
							</div>

							<div className="col-lg-12 col-sm-6 mt-4">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Safety & Mobility</small>
                                    <div className="icon">
                                        <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  

							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

		

      </Fragment>
    )
}

export default SafetyMobility;
