import React, {useState, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';

import {Helmet} from "react-helmet";

function TipsIdeas(props) {
    const [data, setData] = useState(null);
 
    return(
      <Fragment>
		  
		<Helmet>
          <title>The Sunrise Handyman | Tips & Ideas</title>
          <meta name="description" content="The Sunrise Handyman | Tips & Ideas" />
        </Helmet>

        <section className="breadcrumb_area">
        	<div className="container">
        		<div className="breadcrumb_inner">
					<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/van-transparent-small.png' } alt=""/>
        			<h2>Tips & Ideas</h2>
					<ul className="nav">
						<li><Link to="/">Home</Link></li>
						<li>Tips & Ideas</li>
					</ul>
        		</div>
        	</div>
        </section>

        <section className="blog_area p_100">
        	<div className="container">
        		<div className="row blog_inner">
        			<div className="col-lg-9">
        				<div className="blog_list">
        					<article className="blog_item">
        						<Link to="/ultimate-guide-to-selling-your-home" className="blog_img">
									<img className="blog-thumbnail" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/blog-ultimate-guide-thumbnail.png' } alt=""/>
        						</Link>
        						<div className="blog_text">
        							<a href="single-blog.html">
										<h4>The Ultimate Guide to Selling Your Home</h4>
									</a>
									<br/>
									<h5>A to-do list for real estate agents and home-owners - how to get more money for your home.</h5>
        							
									<p>Preparing to put yours or your client's home on the market? With our 25+ years of experience maintaining and repairing homes in the Greater Westchester County Area, we've put together the ultimate guide and checklist to get your home ready for market. From touching up paint to replacing your water heater, we believe this checklist will help you get the best offer for your home.</p>
									<Link className="main_btn main-btn-tips" to="/ultimate-guide-to-selling-your-home">Read more</Link>
        						</div>
        					</article>
        					
        				</div>

						{/*

				
        				<nav aria-label="Page navigation" className="pagination_area">
							<ul className="pagination">
								<li className="page-item active"><a className="page-link" href="#">1</a></li>
								<li className="page-item"><a className="page-link" href="#">2</a></li>
								<li className="page-item">
									<a className="page-link" href="#" aria-label="Next">
										<i className="fa fa-angle-right" aria-hidden="true"></i>
									</a>
								</li>
							</ul>
						</nav>

						*/}
					</div>

        			<div className="col-lg-3">
        				<div className="left_s_widget">
        					<aside className="left_widget list_wd">
        						<ul className="nav flex-column">
        							<li className="active"><a href="air-conditioning.html">Service & Repairs</a></li>
        							<li><a href="heating.html">Electrical</a></li>
        							<li><a href="reparis.html">Plumbing</a></li>
        							<li><a href="hvac.html">Painting</a></li>
        							<li><a href="geothermal.html">Safety & Mobility</a></li>
        							<li><a href="indoor.html">Installation</a></li>
        							<li><a href="maintenance.html">Carpentry</a></li>
        						</ul>
        					</aside>
        					<aside className="left_widget contact_wd">
        						<img src="img/service/service-widget-img.png" alt=""/>
        						<div className="text">
        							<p><span>Available</span> for Emergency Service</p>
        							<h4>Give us a call</h4>
        							<a className="main_btn" href="#">Call Now</a>
        						</div>
        					</aside>
        				</div>
        			</div>
        		</div>


        	</div>
        </section>

      </Fragment>
    )
}

export default TipsIdeas;