import React, {useState} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import TipsIdeas from './pages/TipsIdeas';
import Testimonials from './pages/Testimonials';
import Error_404 from './pages/Error_404';
import Services from './pages/Services';
import ServiceDetail from './pages/ServiceDetail';
import Andersen from './pages/Andersen';
import SellingYourHome from './pages/blog/SellingYourHome';
import Maintenance from './pages/services/Maintenance';
import Mechanical from './pages/services/Mechanical';
import Painting from './pages/services/Painting';
import SafetyMobility from './pages/services/SafetyMobility';
import Installation from './pages/services/Installation';
import Skylights from './pages/services/Skylights';
import FrontDoors from './pages/services/FrontDoors';
import Carpentry from './pages/services/Carpentry';
import Decks from './pages/services/Decks';
import Flooring from './pages/services/Flooring';
import PowerWashing from './pages/services/PowerWashing';
import Drywall from './pages/services/Drywall';
import Tile from './pages/services/Tile';
import Exterior from './pages/services/Exterior';
import Estimate from './pages/Estimate';
import ScrollToTop from './components/utilities/ScrollToTop';

function Routes(props) {
    return (
        <>
            <ScrollToTop />
            <Switch>
                <Route exact path="/" component={ Home }/>
                <Route path="/home" component={ Home }/>

                <Route path="/about" component={ About }/>

                <Route path="/contact" component={ Contact }/>
                <Route path="/estimate" component={ Estimate }/>

                <Route path="/testimonials" component={ Testimonials }/>

                <Route path="/services" component={ Services }/>

                <Route exact path="/service/maintenance" component={ Maintenance }/>
                <Route exact path="/service/mechanical-repairs" component={ Mechanical }/>
                <Route exact path="/service/painting" component={ Painting }/>
                <Route exact path="/service/safety-and-mobility" component={ SafetyMobility }/>
                <Route exact path="/service/installation" component={ Installation }/>
                <Route exact path="/service/appliances" component={ Installation }/>
                <Route exact path="/service/appliance-installation" component={ Installation }/>
                <Route exact path="/service/skylights" component={ Skylights }/>
                <Route exact path="/service/front-doors" component={ FrontDoors }/>
                <Route exact path="/service/carpentry" component={ Carpentry }/>
                <Route exact path="/service/decks" component={ Decks }/>
                <Route exact path="/service/flooring" component={ Flooring }/>
                <Route exact path="/service/power-washing" component={ PowerWashing }/>
                <Route exact path="/service/drywall" component={ Drywall }/>
                <Route exact path="/service/tile" component={ Tile }/>
                <Route exact path="/service/exterior" component={ Exterior }/>

                <Route path="/tips" component={ TipsIdeas }/>
                <Route path="/projects" component={ TipsIdeas }/>

                <Route path="/Andersen" component={ Andersen }/>

                <Route path="/ultimate-guide-to-selling-your-home" component={ SellingYourHome } />

                <Route path="/404" component={ Error_404 }/>

                <Redirect to="/404"/>
            </Switch>
        </>
    );
}

export default Routes;


