import React, {useState, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';

import {Helmet} from "react-helmet";

function Testimonials(props) {
    const [data, setData] = useState(null);
 
    return(
      <Fragment>
        <Helmet>
          <title>The Sunrise Handyman | Testimonials</title>
          <meta name="description" content="The Sunrise Handyman | Testimonials" />
        </Helmet>

        <section className="breadcrumb_area">
        	<div className="container">
        		<div className="breadcrumb_inner">
					<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/van-transparent-small.png' } alt=""/>
        			<h2>Testimonials</h2>
					<ul className="nav">
						<li><a href="index.html">Home </a></li>
						<li>Testimonials</li>
					</ul>
        		</div>
        	</div>
        </section>

        <section class="testimonials_area pad_top">
        	<div class="container">
        		<div class="main_title">
        			<h2>From Our Customers</h2>
        			<p>Don't just take it from us... we stand behind our promise as your personal home improvement consultant and trusted resource for all of your needs.</p>
                    <br/>
                    <p>Looking to share your own feedback? Let us know how we did! <Link to="/contact">Contact Us</Link></p>
        		</div>
        		<div class="testimonials_inner">
        			<div class="row">
        				<div class="col-lg-4 col-md-6">
        					<div class="text_item_box pt-50">
        						<h2>Pat</h2>
        						<h5>Somers, NY</h5>
                                <p><b>Sunrise Handyman</b> is the perfect way to get rid of those never ending to-do lists. Whether it's one project or a lot of little things that never get done, this is the solution!</p>
        						<i>“</i>
        					</div>
        				</div>
        				<div class="col-lg-4 col-md-6">
        					<div class="text_item_box pt-50">
        						<h2>MF</h2>
        						<h5>Yorktown, NY</h5>
                                <p>The <b>Sunrise Handyman</b> helped us with a variety of projects (tub recaulking, floor sanding, ceiling repair) to get our house ready for sale. The repairs made all the difference ... Thanks!</p>
        						<i>“</i>
        					</div>
        				</div>
						<div class="col-lg-4 col-md-6">
        					<div class="text_item_box pt-50">
        						<h2>Robert C.</h2>
        						<h5>Cortlandt Manor, NY</h5>
								<p>We have used Sunrise for a variety of projects over the last several years. This included replacing our kitchen floor (and ripping up two old layers underneath), replacing exterior trim (rotted wood replaced with Azek), replacing interior doors and miscellaneous carpentry.</p>
        						<i>“</i>
        					</div>
        				</div>
                        <div class="col-lg-4 col-md-6">
        					<div class="text_item_box pt-50">
        						<h2>Bruce</h2>
        						<h5>Chappaqua, NY</h5>
                                <p>I have used <b>Sunrise Handyman</b> for a number of home repair/ renovation projects over the last   several years. I have found them to be exemplary in all respects including scheduling, estimates, repairs. Some of the projects were unconventional which posed no problem and were executed in the same expert and professional manner. I recommend them without hesitation.</p>
        						<i>“</i>
        					</div>
        				</div>
                        <div class="col-lg-4 col-md-6">
        					<div class="text_item_box pt-50">
        						<h2>Liza K</h2>
								<h5>Katonah, NY</h5>
								<p>We hired Sunrise for a ceiling issue, which was related to a plumbing issue, possible structural issue... Sunrise sent multiple experts over to give us the best course of action, they were professional, conscientious and respectful in this CoVid environment, not to mention completed the job quickly, came on time, cleaned up thoroughly, and did an all-around excellent job. AND were very fair in their pricing! I would not hesitate to use them again, or recommend them highly.</p>
        						<i>“</i>
        					</div>
        				</div>
                        <div class="col-lg-4 col-md-6">
        					<div class="text_item_box pt-50">
        						<h2>GG C.</h2>
        						<h5>New York, NY</h5>
								<p>I can't speak highly enough of Sunrise.</p>
								<p>The sales manager who came to our home gave us all the possible options and worked with our budget. The staff worked closely with us to fit their crew into our schedule.  The final product--installation and painting of a number of French doors--is better than I could have imagined it could look.</p>
								<p> My experience with Sunrise was excellent from start to finish.</p>
        						<i>“</i>
        					</div>
        				</div>
        			</div>
        		</div>
        	</div>
        </section>

      </Fragment>
    )
}

export default Testimonials;