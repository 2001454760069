import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function Installation(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Installation" pageTitle="Installation" pageLink="/installation " />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
						<div className="s_details_main">
							{ /*<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/s-details-2.jpg' } alt=""/>*/ }
							<h4 className="capitalize">Installation</h4>
							<p>You're in good hands with our installation services. We are backed by multiple household brands such as Andersen Windows & Doors and VELUX Skylights as certified installers. We understand appliances break down, window and door offerings become more efficient, and new furniture is difficult to construct. We install everything from flooring, kitchen counters, light fixtures, kitchen appliances, fireplaces, and more.</p>
						</div>
						<div className="row maintence_column">
                            <div className="col-lg-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/windows_doors.jpeg' } alt=""/></div> 
							</div>
							<div className="col-lg-7">
								<div className="maintence_text">
									<h4>Windows & Doors</h4>
                                    <p>Windows and doors need to be carefully installed to ensure manufacturer warranty and energy efficiency, that's why we're here to give you confidence with our Andersen Windows & Doors installation certification. We take care of all areas of installation including:</p>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Storm door installation </li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Window and door alignment</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Hardware and handles</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Conversion Kits</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Andersen parts and service</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Window sash install and replacement</li>
									</ul>
								</div>
							</div>

                            <div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4>Skylights</h4>
                                    <p>We're a certified contractor of VELUX Skylights and stand by their no-leak gaurantee. Adding skylights adds more natural light to any room with options for automatic opening and closing to allow air flow. Give us a call and we'll be happy to recommend which type of VELUX skylight would work best for your room.</p>
								</div>
							</div>
                            <div className="col-lg-5 mt-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/skylights.jpg' } alt=""/></div> 
							</div>

                            <div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4>Countertops and Cabinetry</h4>
                                    <p>Ask for a kitchen or bathroom consultation.. we have a portfolio with over 10 years of custom kitchen and bathroom design, carpentry, and installation. We can help you pick out energy efficient and suitable appliances and install them for you. We have experience in installing all types of kitchen and bathroom countertops, kitchen islands, and built-in appliances.</p>
								</div>
							</div>
                            <div className="col-lg-5 mt-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/kitchen.jpeg' } alt=""/></div> 
							</div>

                            <div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4>Front Doors & Entryways</h4>
                                    <p>Make a positive first impression of your home with a new front door. Make a solid first impression with a solid front door. As a certified Therm-Tru Door installer, we can recommend the most durable and energy efficient doors that are suitable for your home. We take of all areas of installation including measurement, fitting, hardware, locks, and maintenance.</p>
								</div>
							</div>
                            <div className="col-lg-5 mt-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/frontdoors.jpeg' } alt=""/></div> 
							</div>

                            <div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4>Appliances & Furniture</h4>
                                    <p>Make a positive first impression of your home with a new front door. Make a solid first impression with a solid front door. As a certified Therm-Tru Door installer, we can recommend the most durable and energy efficient doors that are suitable for your home. We take of all areas of installation including measurement, fitting, hardware, locks, and maintenance.</p>
								</div>
							</div>
                            <div className="col-lg-5 mt-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/appliances.jpeg' } alt=""/></div> 
							</div>

                            <div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4>Flooring, Tile & Masonry</h4>
                                    <p>Make a positive first impression of your home with a new front door. Make a solid first impression with a solid front door. As a certified Therm-Tru Door installer, we can recommend the most durable and energy efficient doors that are suitable for your home. We take of all areas of installation including measurement, fitting, hardware, locks, and maintenance.</p>
								</div>
							</div>
                            <div className="col-lg-5 mt-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/flooring.jpg' } alt=""/></div> 
							</div>

							<div className="col-lg-8 mt-5">
								<div className="maintence_text">
									<h4>TV Mounting & Fireplaces</h4>
                                    <p>Wall mounting your TV can be an easy way to modernize your living room, bedroom or basement while gaining extra space. However, mounting your new TV can be a daunting task. How do you it will hold the weight? Leave it to the professionals..</p>
									<p>Add warmth to your family room or bedroom with a new fireplace and surround. Fireplaces are the perfect center piece to any room and can give it that cozy, homey feel. Give us a call.. we've installed countless TVs and fireplaces over the years.</p>
								</div>
							</div>
							<div className="col-lg-4 mt-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/tv-mount.jpeg' } alt=""/></div> 
							</div>

							<div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Installation</small>
                                    <div className="icon">
									<Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  

							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

		

      </Fragment>
    )
}

export default Installation;
