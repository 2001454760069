import React, {useState, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';

import LazyLoad from 'react-lazyload';

import Breadcrumbs from "../components/Breadcrumbs";

import Clients from "../components/Clients"
import ReviewsCarousel from "../components/ReviewsCarousel";

import {Helmet} from "react-helmet";

function Services(props) {
    const [data, setData] = useState(null);
 
    return(
      <Fragment>

        <Helmet>
          <title>The Sunrise Handyman | Services</title>
          <meta name="description" content="The Sunrise Handyman | Services - Experienced, licensed, and trusted handyman services in NYC, Westchester, Putnam County, and Connecticut." />
        </Helmet>
       <Breadcrumbs pageName="Services" pageLink="services" pageTitle="services" />

       <section className="main_service_area pad_top">
        	<div className="container">
        		<div className="main_title">
        			<h2>What We Offer</h2>
        			<h4 className="primary-red">Experienced, licensed, and trusted handyman services...</h4>
                    <p>With over 25 years of service, there's a good chance we have experience in the services you are looking for, and we have worked on your home in the past! We offer a range of both residential and commercial services from general assembly, custom decks, window and door installation, to major renovation and repairs. Don't see what you're looking for? Give us a call!</p>
        		</div>
        		<div className="row main_service_inner">
        			<div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/Maintenance">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/maintenance.jpeg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Maintenance</h4>
									<p>Regular maintenance will help keep your home from going into disrepair, preventing larger issues and expenses from accumulating. Let us take care of your to-do list!</p>
									<Link Link to="/service/Maintenance" className="more_btn">More info</Link>
								</div>
							</div>
						</Link>
        			</div>
        			<div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/mechanical-repairs">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/mechanical.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Mechanical Repairs</h4>
									<p>We can help point you in the right direction and diagnose your electrical, plumbing, and HVAC issues. We have a network of local expert tradesmen.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
        			<div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/painting">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/painting.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Painting</h4>
									<p>We do it all... painting, sealing, and staining. Adding color to your wall or looking to bring life to old furniture, we have a dedicated team of painting pros to get it done.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
        			<div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/safety-and-mobility">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/mobility.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Safety & Mobility</h4>
									<p>We can make the neccessary upgrades to meet the requirements of building code standards or help aging home-oweners feel more comfortable in their homes.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
        			<div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/installation">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/installation.jpeg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Installation</h4>
									<p>Whatever it is, we've probably installed it before. Appliances, flooring, windows, doors, kitchen cabinetry.. we do it all!</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
        			<div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/skylights">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img className="img-sponsor-thumbnail sponsor-velux" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/vlux_certified.jpeg' } alt="Certified VELUX Skylight Installer"/>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/skylights.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Skylights</h4>
									<p>Skylights are a speciality of ours. As a certified VELUX<span style={{ fontSize: "0.5em" }}>&#174;</span> installer, we stand behind thier quality and no-leak promise.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
        			<div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/front-doors">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img className="img-sponsor-thumbnail sponsor-thermatru" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/therma-tru.png' } alt="Therma-Tru Certified Installer"/>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/frontdoors.jpeg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Front Doors</h4>
									<p>Make a bold first impression with a solid front door. As a certified ThermaTru Front Door Installer, you're in good hands.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
					<div className="col-lg-3 col-md-4 col-sm-6">
						<Link Link to="/service/decks">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img className="img-sponsor-thumbnail sponsor-trex" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/trexpro-platinum.jpeg' } alt="TREX Pro Installer - TREX Decking"/>
										<img className="img-sponsor-thumbnail sponsor-timbertech" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/timbertech.png' } alt="TimberTech Decking"/>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/sunrise-decking.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Deck Renovation</h4>
									<p>Maintenance, repairs, power-washing, painting, and more. We're certified TREX Pros (Platinum) with a portfolio of custom decks.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/carpentry">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img className="img-sponsor-thumbnail sponsor-thermatru" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/sunrise-carpentry.jpg' } alt="Sunrise Carpentry"/>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/carpentry.jpeg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Carpentry</h4>
									<p>We have a full team of expert craftsmen along with our parent company, Sunrise Carpentry, to build and install decks, cabinets, shelving, and more.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/flooring">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/flooring.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Flooring</h4>
									<p>We off complete solutions for all flooring types including tile, laminate, vinyl, and hardwood. Wether you're looking to re-tile your bathroom or full home renovation..</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/Andersen">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img className="img-sponsor-thumbnail" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/Certified_Contractor_Square_Logo_4_Color_White_background_for_digital_.jpg' } alt="Andersen Windows & Doors - Certified Contractor"/>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/windows_doors.jpeg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Windows & Doors</h4>
									<p>We are a certified Andersen contractor with a team of expert installers of all Andersen windows and doors. We offer a list of window and door services..</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/Power-washing">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/power_washing.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Power Washing</h4>
									<p>Freshen up your home siding or give life to that old deck. Power-washing can really brighten up your home or if you're looking to move, a power-wash can help you get the best offer.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/drywall">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/drywall.jpg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Drywall</h4>
									<p>Patching up holes, wear n' tear or expanding your home? Transform the look of your walls with help from our professional team.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/tile">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/tile.jpeg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Tile & Masonry</h4>
									<p>We have experience working with all types of tile - granite, porcelain, glass, limestone, ceramic, sandstone, onyx, marble... Give us a call!</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
                    <div className="col-lg-3 col-md-4 col-sm-6">
						<Link to="/service/exterior">
							<div className="m_service_item">
								<div className="service_img">
									<LazyLoad>
										<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/exterior.jpeg' }  alt=""/>
									</LazyLoad>
								</div>
								<div className="service_text">
									<h4>Exterior</h4>
									<p>Boost your curb appeal or general exterior of your home with a new patio, pathway or driveway. We have a portfolio of exterior offerings.</p>
									<span>More info</span>
								</div>
							</div>
						</Link>
        			</div>
        		</div>
        	</div>
        </section>

        <Clients type="clients" />

      </Fragment>
    )
}

export default Services;