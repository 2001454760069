import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function Maintenance(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Service & Maintenance" pageTitle="Service & Maintenance" pageLink="/maintenance" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
						<div className="s_details_main">
							{ /*<img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/s-details-2.jpg' } alt=""/>*/ }
							<h4 className="capitalize">Service & Maintenance</h4>
							<p>Like all home-owners, do you have a never-ending to-do list? Without regular maintenance and upgrades to your home, larger issues can add up and make you pay a lot our of pocket costs all at once. Regular service and maintenance is also important to retain the value of your home when the time comes to sell.</p>
							<p>We have a team of expert craftsmen including teams dedicated to carpentry, renovation, and home remodeling.</p>
						</div>
						<div className="row maintence_column">
							<div className="col-lg-3">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/interior_maintenance.jpeg' } alt=""/></div> 
							</div>
							<div className="col-lg-9">
								<div className="maintence_text">
									<h4>Interior Service & Maintenance</h4>
									<p>Wether you're looking to expand your home, finish your basement or replacing light switches, no interior home task is too big or small.</p>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Bathroom Maintenance</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Kitchen Maintenance</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Appliance Installation</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Light switches, light fixtures, shower vents, faucet repair</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Tile, flooring, windows and doors</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-7 mt-5">
								<div className="maintence_text">
									<h4>Exterior Service & Maintenance</h4>
									<p>Outdoor maintenance can be exhausting, especially in our North-Eastern seasonal climate. However, regular seasonal maintenance can dramatically improve the look and value of your home.</p>
									<p>We're available Spring, Summer, Autumn, and Winter to provide a list of services from boosting your curb appeal to building custom decks and patios.</p>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Custom Decks (Certified TREX Deck and TimberTech installers)</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Siding repair and replacement</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Drains and gutters</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Power-washing</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Windows and doors</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Pool house design and installation</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Landscaping</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Fences and gates</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-5 mt-5">
								<div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/maintenance.jpeg' } alt=""/></div> 
								<div className="maintence_img mt-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/outdoors.jpeg' } alt=""/></div> 

							</div>

							<div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Service & Maintenance - Interior, Exterior, Seasonal</small>
                                    <div className="icon">
									<Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  
							
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

		

      </Fragment>
    )
}

export default Maintenance;
