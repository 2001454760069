import React, {useState, useEffect} from "react";

function ReviewsCarousel(props) {

    return(
        <section className="client_testi_area pt_100 pb-50">
        <div className="container">
            <div className="single_border_title">
                <h2>Customer Reviews</h2>
            </div>
            <div className="c_testi_slider owl-carousel">
                <div className="item">
                    <div className="b_testi">
                        <h4>"Very fair pricing..."</h4>
                        <p>We hired Sunrise for a ceiling issue, which was related to a plumbing issue, possible structural issue... Sunrise sent multiple experts over to give us the best course of action, they were professional, conscientious and respectful in this CoVid environment, not to mention completed the job quickly, came on time, cleaned up thoroughly, and did an all-around excellent job. AND were very fair in their pricing! I would not hesitate to use them again, or recommend them highly.</p>
                        <h5>- Liza K <br/>Katonah, NY</h5>
                        <ul className="nav">
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="item">
                    <div className="b_testi">
                        <h4>"The quality of the work is  A+..."</h4>
                        <p>We have used Sunrise for a variety of projects over the last several years. This included replacing our kitchen floor (and ripping up two old layers underneath), replacing exterior trim (rotted wood replaced with Azek), replacing interior doors and miscellaneous carpentry.</p>
                        <p>The quality of the work is  A+, in all cases. Different teams tackle different projects or parts of projects, so there are "experts" in each area handling their specialty.</p>
                        <h5>Robert C. <br/>Cortlandt Manor, NY</h5>
                        <ul className="nav">
                        <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="item">
                    <div className="b_testi">
                        <h4>"I can't speak highly enough of Sunrise..."</h4>
                        <p>I can't speak highly enough of Sunrise.</p>
                        <p>The sales manager who came to our home gave us all the possible options and worked with our budget. The staff worked closely with us to fit their crew into our schedule.  The final product--installation and painting of a number of French doors--is better than I could have imagined it could look.</p>
                        <p> My experience with Sunrise was excellent from start to finish.</p>
                        <h5>- GG C. <br/>New York, NY</h5>
                        <ul className="nav">
                        <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="item">
                    <div className="b_testi">
                        <h4>"This is the solution!"</h4>
                        <p><b>Sunrise Handyman</b> is the perfect way to get rid of those never ending to-do lists. Whether it's one project or a lot of little things that never get done, this is the solution!</p>
                        <h5>Pat <br/>Somers, NY</h5>
                        <ul className="nav">
                        <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                    </div>
                </div>
                <div className="item">
                    <div className="b_testi">
                        <h4>"Made all the difference..."</h4>
                        <p>The <b>Sunrise Handyman</b> helped us with a variety of projects (tub recaulking, floor sanding, ceiling repair) to get our house ready for sale. The repairs made all the difference ... Thanks!</p>
                        <h5>MF <br/>Yorktown, NY</h5>
                        <ul className="nav">
                        <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
)}

export default ReviewsCarousel;