

import React, {useState, useEffect} from "react";
import { Fragment } from "react";
import { Link } from 'react-router-dom';

function ServiceSidebar(props) {

    return(

        <div className="col-lg-3">
            <div className="left_s_widget">
            <aside className="left_widget contact_wd">
                    <img src="img/service/service-widget-img.png" alt=""/>
                    <div className="text">
                        <p><span>Available</span> for Emergency Service</p>
                        <h4>Give us a call</h4>
                        <a className="main_btn border-white-2px" href="tel:9142487368">Call Now</a>
                    </div>
                </aside>
                <aside className="left_widget list_wd">
                    <ul className="nav flex-column">
                        <li><Link to="/service/maintenance">Maintenance</Link></li>
                        <li><Link to="/service/mechanical-repairs">Mechanical Repairs</Link></li>
                        <li><Link to="/service/painting">Painting</Link></li>
                        <li><Link to="/service/safety-and-mobility-mobility">Safety & Mobility</Link></li>
                        <li><Link to="/service/installation">Installation</Link></li>
                        <li><Link to="/service/skylights">Skylights</Link></li>
                        <li><Link to="/service/front-doors">Front Doors</Link></li>
                        <li><Link to="/service/carpentry">Carpentry</Link></li>
                        <li><Link to="/service/decks">Decks</Link></li>
                        <li><Link to="/service/flooring">Flooring</Link></li>
                        <li><Link to="/Andersen">Windows & Doors</Link></li>
                        <li><Link to="/service/power-washing">Power Washing</Link></li>
                        <li><Link to="/service/drywall">Drywall</Link></li>
                        <li><Link to="/service/tile">Tile</Link></li>
                        <li><Link to="/service/exterior">Exterior</Link></li>
                    </ul>
                </aside>
            </div>
        </div>

    )
};

export default ServiceSidebar;
