import React, {useState, useEffect, Fragment} from "react";
import { Link } from 'react-router-dom';

import Hero from "../components/Hero";
import Clients from "../components/Clients"
import ReviewsCarousel from "../components/ReviewsCarousel";

function About(props) {
    const [data, setData] = useState(null);
 
    return(
      <Fragment>

        <section className="breadcrumb_area">
        	<div className="container">
        		<div className="breadcrumb_inner">
                    <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/van-transparent-small.png' } alt=""/>
        			<h2>About Us</h2>
					<ul className="nav">
						<li><a href="index.html">Home </a></li>
						<li>About Us</li>
					</ul>
        		</div>
        	</div>
        </section>

        <section className="about_text_area p_100">
        	<div className="container">
        		<div className="ab_text_inner">
        			<div className="ab_text_item">
        				<h3>About <b className="primary-red">The Sunrise Handyman</b></h3>
                        <p>Established in 2008 as a division of Sunrise Carpentry, we opened with a focus on providing home maintenance and repairs. We believe in helping you protect your largest investment - your home! However, most people do not treat their homes with the same amount of love as compared to their cars or other assets. The Sunrise Handyman is here to complete that task list that just won't go away. We're a team of experienced, and knowledgeable craftsmen, and designers who are on the cutting edge in the remodeling industry. The Sunrise Handyman is a business designed to service customers just like you. </p>
                        <br/>
        				<p>The Sunrise Handyman is more than your local man with a van, we are a licensed and insured home improvement team serving the community as a division of our parent company Sunrise Carpentry. We believe in our motto - <i>One Call Does it All...</i> because we believe we have truly seen it all in the last 25 years. With that experience, we provide a wealth of knowledge while maintaining personal relationships and loyalty with our customers.</p>
        			</div>
        			<div className="ab_text_item_two">
        				<p></p>
        			</div>
        			<div className="row ab_list">
        				<div className="col-lg-7">
        					<h2 style={{ color: "#cf3535" }}>We are...</h2>
        					<ul className="nav flex-column about-page-highlights"><br/>
        						<li> <h5><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> A licensed and authorized Andersen installer</h5></li>
								<br/>
								<li> <h5><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Certified Thermatru front door installer</h5></li>
								<br/>
        						<li> <h5><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Licensed VELUX skylight installer</h5></li>
								<br/>
								<li> <h5><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> TrexPro Platinum - Professional Trex decks installer</h5></li>
								<br/>
								<li> <h5><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> TimberTech Partner - Certified TimberTech installer</h5></li>
								<br/>
        						<li> <h5><img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Licensed and insured to operate in Westchester, Putnam County, and Connecticut</h5></li>
        					</ul>
        				</div>
        				<div className="col-lg-5 d-flex justify-content-center">
        					<div className="ab_logo text-center">
        						<img className="certifications-group-image" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/certifications-group-2022.png' } alt=""/>
        					</div>
        				</div>
        			</div>
        		</div>

        	</div>
        </section>

        <section className="our_moto_area">
        	<div className="left_side">
        		<img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/our-motto.png' } alt=""/>
        	</div>
        	<div className="right_side">
        		<div className="moto_text">
        			<h1>Our Motto :</h1>
					<h2>The Home of the <span className="black">One Call Does it All...</span></h2>
        			<p>With an experienced team of home improvement speicalists, we stand behind our motto and gaurantee a timely dispatch and service to your home with one call.</p>
                    <div className="mt-50">
                    <a className="main_btn secondary_hero_btn" href="https://sunrisecarpentry.com" target="_blank" rel="noreferrer">Get an Estimate</a>
                    </div>
        		</div>
        	</div>
        </section>

        <ReviewsCarousel/>
        <div className="center-all pb-100">
            <Link to="/services" className="main_btn main_btn_alt-bg">View Testimonials</Link>
        </div>

		<section className="our_moto_area m-100 red-bg col-12 pt-100px pb-100px mb-0">
			<div className="moto_text container">
				<h1 className="text-white">Sunrise Carpentry</h1>
				<p className="text-white">The Sunrise Handyman repair services are one of the many home solutions available as a division of Sunrise Carpentry.</p>
				<br/>
				<p className="text-white">Looking for more services? Visit <b><u><a style={{ color:"#fff" }} rel="noreferrer" href="https://sunrisecarpentry.com" target="_blank">sunrisecarpentry.com</a></u></b> or step into our <b>showroom in Yorktown Heights, NY</b>. We have a team of talented craftsman and designers to provide home improvement & remodeling, renovation, custom decks and more.</p>
				<div className="mt-50">
					<a className="main_btn secondary_hero_btn" href="https://sunrisecarpentry.com" target="_blank" rel="noreferrer">View Services</a>
					<a className="main_btn main_btn_border ml-1em" rel="noreferrer" href="https://www.google.com/maps/place/Sunrise+Carpentry/@41.3145367,-73.8149436,13z/data=!4m8!1m2!2m1!1ssunrise+carpentry!3m4!1s0x89c2b4009c261e55:0xfbe88183cb72e3d3!8m2!3d41.3147395!4d-73.748815?hl=en" target="_blank">Find Our Showroom</a>
				</div>
			</div>
		</section>

		<section className="col-12 pl-0 pr-0">
			<img className="showroom-image" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/sunrise-showroom.jpeg' } alt=""/>
		</section>

        <div className="pb-100"></div>

        <Clients type="clients" />

      </Fragment>
    )
}

export default About;