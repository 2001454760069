
import React from "react";
import { Link } from 'react-router-dom';

function Breadcrumbs(props) {
    console.log(props.link)
    return(
        <section className={ props.background_size === "small" ? "breadcrumb_area small-bg" : "breadcrumb_area" }>
            <div className="container">
                <div className="breadcrumb_inner">
                    { !props.hide_van ? <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/van-transparent-small.png' } alt=""/> : null }
                    <h2>{ props.pageTitle }</h2>
                    <ul className="nav">
                        <li><Link to="/Home">Home </Link></li>
                        <li><Link to={ props.link }>{ props.pageName }</Link></li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Breadcrumbs;