import React, {useState, useEffect, Fragment} from "react";
import { Link, Redirect } from 'react-router-dom';
import Breadcrumbs from "../../components/Breadcrumbs";
import ServiceFooter from "../../components/ServiceFooter";
import ServiceSidebar from "../../components/ServiceSidebar";

function FrontDoors(props) {
    const [data, setData] = useState(props);
 
    return(
      <Fragment>

        <Breadcrumbs pageName="Front Doors" pageTitle="Front Doors" pageLink="/front-doors" />

        <section className="service_details_area p_100">
        	<div className="container">
        		<div className="row flex-row-reverse">
				
        			<div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-7">
                                <div className="maintence_text">
                                    <h4 className="black">Front Doors</h4>
                                    <p>Your front door is where your home begins. It is the entrance to you family's space and life biggest investment. Wether you're looking for repair or a new installation, we have a certified team to help consult, repair, install, and maintain your entryway.</p>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-5">
							    <div className="maintence_img"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/Logo_TT-CertInstaller_Horiz.jpeg' } alt=""/></div> 
						    </div>
                        </div>

						<div className="row maintence_column">
							<div className="col-lg-7">
								<div className="maintence_text">
									<h4>Certified <span className="primary-red">Therma-Tru</span> Door System Installer</h4>
                                    <p>We are proud to be a Certified Therma-Tru Door System Installer. Therma-Tru are the nation's leading fiberglass and steel exterior door system provider with over 70 million doors produced since 1962, leading the industry in efficieny and wood and glass innovation.</p>
                                    <a href="https://www.thermatru.com/explore-products/Difference/" rel="noreferrer" target="_blank" className="btn submit_btn mb-4">Learn About the Therma-Tru Difference</a>
                                    <p>As a Certified Therma-Tru Installer, we provide the following exterior and entry door services:</p>
									<ul className="nav flex-column">
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Energy Efficient Front Doors</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Composite Door Frames</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Sidelite and Glass Installation</li>
										<li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Therma-Tru Hardware and Accessories</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Fiberglass and Steel Front Doors</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Therma-Tru Replacement Parts</li>
                                        <li> <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/check.png' } alt=""/> Multi-lock System Installation</li>
									</ul>
								</div>
							</div>
                            <div className="col-lg-5">
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/frontdoor-3.jpeg' } alt=""/></div> 
                                <div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/frontdoor-2.jpeg' } alt=""/></div> 
								<div className="maintence_img mb-4"><img className="img-fluid" src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/service/frontdoor-1.jpeg' } alt=""/></div>
							</div>

                            <div className="col-lg-12 col-sm-12 mt-5">
                                <div className="rp_box_item service-detail-estimate">
                                    <h3>Request Service</h3>
                                    <small>Exterior and Entry Doors - Repair and Installation</small>
                                    <div className="icon">
                                    <Link to="/estimate" className="main_btn">Get an Estimate</Link>
                                    </div>
                                </div>
                            </div>  
							<ServiceFooter />
						</div>
        			</div>
        			<ServiceSidebar />
				</div>
        	</div>
        </section>

		

      </Fragment>
    )
}

export default FrontDoors;
