
import React, {useState, useEffect} from "react";

function Clients(props) {
 if (props.type === "social") {
        return(
            <section className="client_service_area" style={{ paddingTop: "70px", paddingBottom: "50px" }}>
                <div className="container">
                    <div className="client_slider owl-carousel">
                        <div className="item">
                            <a href="https://www.facebook.com/SunriseHandyman/" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/hp-hero-slider/facebook-300x300.png' } alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://www.thermatru.com/" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/hp-hero-slider/thermatru-300x300.png' } alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://www.andersenwindows.com/where-to-buy/42/427293/#about" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/hp-hero-slider/andersen-300x300.png' } alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/bbb-300x300.png' } alt=""/>
                        </div>
                        <div className="item">
                            <a href="https://www.trex.com/find-a-contractor/details/?PRO=12473TP#/0" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/trexpro-300x300.png' } alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://www.veluxusa.com/" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/velux-300x300.png' } style={{ maxWidth: "125px"}} alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/timbertech-300x300.png' } style={{ maxWidth: "160px"}} alt=""/>
                        </div>
                        {/*
                        <div className="item" style={{ maxHeight:"100px", width:"70px" }}>
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/somers-logo.png' }/>
                        </div>
                        */}
                    </div>
                </div>
            </section>
        )
    } else return(
            <section className="client_service_area" style={{ paddingTop: "70px", paddingBottom: "125px" }}>
                <div className="container">
                    <div className="single_title blue text-center">
                        <h2>Licenses and Affiliations</h2>
                    </div>
                    <div className="client_slider owl-carousel">
                        <div className="item">
                            <a href="https://www.veluxusa.com/" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/velux-300x300.png' } style={{ maxWidth: "125px"}} alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/sunrise-carpentry-300x300.png' } alt=""/>
                        </div>
                        <div className="item">
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/hp-hero-slider/thermatru-300x300.png' } alt=""/>
                        </div>
                        <div className="item">
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/timbertech-300x300.png' } style={{ maxWidth: "160px" }} alt=""/>
                        </div>
                        <div className="item ml-0 mr-0" style={{ marginRight: '0 !important' }}>
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/epa-logo-300x300.png' } style={{ maxWidth: "120px", marginRight: '0' }} className="ml-0 mr-0" alt=""/>
                        </div>
                        <div className="item">
                            <a href="https://www.trex.com/find-a-contractor/details/?PRO=12473TP#/0" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/trexpro-300x300.png' } alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <a href="https://www.andersenwindows.com/where-to-buy/42/427293/#about" target="_blank" rel="noreferrer">
                                <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/hp-hero-slider/andersen-300x300.png' } alt=""/>
                            </a>
                        </div>
                        <div className="item">
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/img/bbb-300x300.png' } alt=""/>
                        </div>
                        {/*
                        <div className="item" style={{ maxHeight:"100px", width:"70px" }}>
                            <img src={ 'https://sunrise-handyman.s3.us-east-2.amazonaws.com/assets/logos/somers-logo.png' }/>
                        </div>
                        */}
                    </div>
                </div>
            </section>
      )
}

export default Clients;